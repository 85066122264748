




import { Component, Vue, Prop } from 'vue-property-decorator'
import PlanningLocation from '../PlanningLocation.vue'
import type { ScheduledMeeting } from '@/digiteventApi'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import { getMeetingLocationsName } from '../../../matchmaking/services'
import { joinIfExist } from '@/utils/string'

@Component({
  components: { PlanningLocation }
})
export default class MeetingLocation extends Vue {
  @Prop({ required: true }) readonly meeting!: ScheduledMeeting

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get areas() {
    return this.storeEvent.modules_data.programme.matchmakingAreas
  }

  get location(): string {
    const location = getMeetingLocationsName({
      meeting: this.meeting,
      areas: this.areas
    })
    if (!location) {
      return this.$t('MATCHMAKING_MEETING_VISIO_LOCATION')
    }
    return joinIfExist([location.areaName, location.meetingPointName], ' - ')
  }
}
