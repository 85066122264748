





import { Vue, Component, Prop } from 'vue-property-decorator'
import sanitizeHtml from 'sanitize-html'

@Component({})
export default class SanitizedHtmlRenderer extends Vue {
  @Prop({ required: true, type: String }) readonly unsafeHtml!: string

  get sanitizedHTML() {
    return sanitizeHtml(this.unsafeHtml, {
      allowedAttributes: {
        '*': ['style'],
        a: ['href']
      },
      // allow native link style
      transformTags: {
        a: (tagName, attribs) => {
          return {
            tagName,
            attribs: {
              ...attribs,
              href: '#'
            }
          }
        }
      }
    })
  }
}
