
















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { DigiCardItemInformation } from '@/components/ui/cardItem'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import type { MatchmakingMeetingPoint } from '@/models/Event/modules/Programme'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'
import { deleteMatchmakingMeetingPoint } from '@/digiteventApi'
import MeetingPointEditionModal from './MeetingPointEditionModal.vue'

@Component({
  components: { MeetingPointEditionModal, DigiDeleteConfirmationModal, DigiSimpleIconButton, DigiCardItemInformation }
})
export default class MatchmakingMeetingPointCard extends Vue {
  @Prop({ required: true }) meetingPoint!: MatchmakingMeetingPoint
  @Prop({ required: true }) zoneId!: string
  @Prop({ required: true }) eventId!: string
  @Ref('confirmationModal') confirmationModal!: DigiDeleteConfirmationModal
  @Ref('editionModal') editionModal!: MeetingPointEditionModal

  isLoading = false

  async deletePoint() {
    this.isLoading = true
    try {
      await deleteMatchmakingMeetingPoint({
        eventId: this.eventId,
        zoneId: this.zoneId,
        pointId: this.meetingPoint._id
      })
    } finally {
      this.isLoading = false
    }
  }
}
