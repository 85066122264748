import type { LocalizedPrimitive } from '@/models/Event/Localized'
import type { Cerfa, GeneralPaymentSettings, PaymentMethods } from '@/models/Event/modules/Payment'
import axios from 'axios'

export async function updateClosedRegistrationSettings(
  eventId: string,
  closedRegistrationMessage: LocalizedPrimitive<string>,
  closingDate: Date | null
): Promise<void> {
  axios.put(`/backoffice/events/registration/${eventId}/closedRegistrationSettings`, {
    closedRegistrationSettings: {
      closingDate,
      closedRegistrationMessage
    }
  })
}

export async function saveCerfa(eventId: string, cerfa: Cerfa): Promise<void> {
  await axios.put(`/backoffice/events/payment/${eventId}/cerfa`, { cerfa })
}

export async function updateGeneralPaymentSettings(
  eventId: string,
  GeneralPaymentSettings: GeneralPaymentSettings
): Promise<void> {
  await axios.put(`/backoffice/events/payment/${eventId}/generalSettings`, { generalSettings: GeneralPaymentSettings })
}

export async function updatePaymentMethods(eventId: string, paymentMethods: PaymentMethods): Promise<void> {
  await axios.put(`/backoffice/events/payment/${eventId}/paymentMethods`, { paymentMethods })
}

export async function saveTermsOfSales(eventId: string, termsOfSales: string): Promise<void> {
  await axios.put(`/backoffice/events/payment/${eventId}/termsOfSales`, { termsOfSales })
}

export async function deleteTicket({ eventId, ticketId }: { eventId: string; ticketId: string }): Promise<void> {
  await axios.delete(`/events/${eventId}/tickets/${ticketId}`)
}

export async function deleteAddon({ eventId, addonId }: { eventId: string; addonId: string }): Promise<void> {
  await axios.delete(`/events/${eventId}/addons/${addonId}`)
}
