












































import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { DigiIconButton, DigiSimpleIconButton } from '@/components/ui/actions'
import RescheduleMeeting from '../../RescheduleMeeting.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import PlanningCard from '../PlanningCard.vue'
import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import CounterPartProfile from './CounterPartProfile.vue'
import PlanningCheckDate from '../PlanningCheckDate.vue'
import MeetingCancelBadge from './MeetingCancelBadge.vue'
import MeetingLocation from './MeetingLocation.vue'
import MeetingDateRange from './MeetingDateRange.vue'
import { cancelMeeting } from '@/digiteventApi'
import { DigiDeleteConfirmationModal } from '@/components/ui/modals'
import { GuestAppProfileFetcher } from '../../../services'
import { findMeetingCounterpart } from '../../utils'
import type { GuestAppProfile } from '@/digiteventApi'
import type { MeetingPlanningItem } from '../../../services/guestPlanning'
import { transformToDigiError } from '../../../../../utils/errorHandling'

@Component({
  components: {
    PlanningCard,
    CounterPartProfile,
    PlanningCheckDate,
    RescheduleMeeting,
    DigiIconButton,
    DigiSimpleIconButton,
    MeetingCancelBadge,
    MeetingDateRange,
    MeetingLocation,
    DigiDeleteConfirmationModal
  }
})
export default class MeetingCard extends Vue {
  @Prop({ required: true }) readonly meeting!: MeetingPlanningItem
  @Prop({ required: true }) readonly guest!: Guest

  @Ref() readonly rescheduleModal!: RescheduleMeeting

  isLoading = false
  counterPartAppProfile: GuestAppProfile | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get counterPartId(): string {
    return findMeetingCounterpart(this.meeting.plannedMeeting, this.guest._id).guestId
  }

  get isCanceled(): boolean {
    return this.meeting.plannedMeeting.status === 'canceled'
  }

  get meetingGuest() {
    return this.meeting.plannedMeeting.guests.find((guest) => guest.guestId === this.guest._id)
  }

  get matchmakingSession(): MatchmakingSession {
    const matchmakingSession = this.storeEvent.modules_data.programme?.eventActivities.find(
      (activity): activity is MatchmakingSession =>
        activity._id === this.meeting.eventActivityId && activity.kind === 'matchmakingSession'
    )
    if (!matchmakingSession) {
      throw new Error(`Could not find matchmaking session with id ${this.meeting.eventActivityId}.`)
    }
    return matchmakingSession
  }

  reschedule() {
    this.rescheduleModal.doReschedule()
  }

  @Ref() readonly deleteConfirmationModal!: DigiDeleteConfirmationModal

  openDeletionModal(): void {
    this.deleteConfirmationModal.open()
  }

  async cancelMeeting(): Promise<void> {
    await cancelMeeting(this.$store.getters.eventId, this.meeting.scheduledItemId)
    this.$emit('deleted')
  }

  async getCounterPartAppProfile() {
    try {
      this.isLoading = true
      this.counterPartAppProfile = await GuestAppProfileFetcher.instance.getProfile(this.counterPartId)
    } catch (e) {
      const digiError = transformToDigiError(e)
      if (!digiError || digiError.slug !== 'SERVERR_NO_GUEST_FOUND') {
        throw e
      }
    } finally {
      this.isLoading = false
    }
  }

  mounted() {
    this.getCounterPartAppProfile()
  }
}
