















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { DigiFormGroup, type DigiFormGroupItem } from '@/components/ui/form/formGroup'
import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import { updateMatchmakingMeetingZone } from '@/digiteventApi'
import { DigiFormLayout2 } from '@/components/ui/form/formLayouts'
import type { MatchmakingMeetingArea } from '@/models/Event/modules/Programme'

@Component({
  components: { DigiFormLayout2, DigiFormGroup }
})
export default class MatchmakingAreaEditionPart extends Vue {
  @Prop({ required: true }) readonly eventId!: string
  @Prop({ required: true }) readonly initialZone!: MatchmakingMeetingArea

  isOpen = false

  isLoading = false
  activity: MatchmakingSession | undefined

  name = ''

  @Watch('initialZone', { immediate: true })
  onInitialZoneChange(): void {
    this.name = this.initialZone.name
  }

  show() {
    this.name = ''
    this.isOpen = true
  }


  get formItems(): DigiFormGroupItem[] {
    return [
      { key: 'name', label: this.$t('MATCHMAKING_CREATE_ZONE_NAME_LABEL'), validationRules: { required: true } }
    ]
  }

  get hasModifications(): boolean {
    return this.name !== this.initialZone.name
  }

  async updateZone(): Promise<void> {
    this.isLoading = true
    try {
      await updateMatchmakingMeetingZone({
        eventId: this.eventId,
        zoneId: this.initialZone._id,
        name: this.name
      })
      await this.$store.dispatch('reloadCurrentEvent')
    } finally {
      this.isLoading = false
    }
  }
}
