import { Type } from 'class-transformer'

export type StickersTransform = 'allUpper' | 'firstUpper' | 'no_transformation'

export type StickerFieldSize = 'Large' | 'Medium' | 'Small'

export type CheckinStickerBadgeKey = 'empty_field' | 'firstname_name' | 'name_firstname' | string

export class BadgeStickersField {
  _id!: string
  label: CheckinStickerBadgeKey = 'empty_field'
  size?: StickerFieldSize
  transform?: StickersTransform
  type?: string
}

export interface LabelSizes {
  mmWidth: number
  mmHeight: number
}

export class BadgeStickersModule {
  printQrCode: boolean = true
  labelSizes!: LabelSizes
  @Type(() => BadgeStickersField)
  fields: BadgeStickersField[] = []
}
