


















import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import type { UnscheduledMeeting } from '@/digiteventApi'
import MeetingRequestAcceptationModal from './MeetingRequestAcceptationModal.vue'
import { ContactProfileModal } from '@/features/audience/contactProfile'
import type { GuestAppProfile } from '@/digiteventApi'
import { GuestAppProfileFetcher } from '../../services'
import { joinIfExist } from '@/utils/string'
import moment from 'moment/moment'
import { findMeetingCounterpart } from '../utils'

@Component({
  components: { ContactProfileModal, MeetingRequestAcceptationModal }
})
export default class MeetingRequestSentItem extends Vue {
  @Prop() readonly meeting!: UnscheduledMeeting
  @Prop() readonly requestBeingCancelled!: string
  @Prop() readonly guestId!: string
  @Ref() readonly meetingRequestAcceptationModal!: MeetingRequestAcceptationModal

  meetingCounterpartAppProfile: GuestAppProfile | null = null

  @Watch('counterpart', { immediate: true })
  async loadCounterpart() {
    this.meetingCounterpartAppProfile = await GuestAppProfileFetcher.instance.getProfile(this.counterpart.guestId)
  }

  get counterpart() {
    return findMeetingCounterpart(this.meeting, this.guestId)
  }

  get counterpartFullName() {
    if (!this.meetingCounterpartAppProfile) return ''
    return joinIfExist([this.meetingCounterpartAppProfile.firstname, this.meetingCounterpartAppProfile.name], ' ')
  }

  get creationDate() {
    return moment(this.meeting.creationDate).fromNow()
  }

  openContactProfileModal() {
    this.$emit('open-profile', this.counterpart.guestId)
  }
}
