import type { MongoDb } from '@/models/MongoDbTools'
import { plainToClass } from 'class-transformer'
import { $enum } from 'ts-enum-util'
import CheckboxCustomField from './fieldTypes/Checkbox'
import DateTimeCustomField from './fieldTypes/DateWithoutTimezone'
import EmailCustomField from './fieldTypes/Email'
import FileCustomField from './fieldTypes/File'
import type { ListFieldOption } from './fieldTypes/List'
import ListCustomField from './fieldTypes/List'
import LongTextCustomField from './fieldTypes/LongText'
import NumberCustomField from './fieldTypes/Number'
import PhoneCustomField from './fieldTypes/Phone'
import TextCustomField from './fieldTypes/Text'
import LinkCustomField from './fieldTypes/Link'

export type CustomFieldId = MongoDb.ObjectId

export enum GuestFieldType {
  Number = 'number',
  Checkbox = 'checkbox',
  List = 'list',
  File = 'file',
  Phone = 'phone',
  Text = 'text',
  Email = 'email',
  LongText = 'longtext',
  DateWithoutTimezone = 'dateWithoutTimezone',
  Link = 'link'
}

export type CustomFieldConstructor =
  | typeof ListCustomField
  | typeof FileCustomField
  | typeof PhoneCustomField
  | typeof TextCustomField
  | typeof CheckboxCustomField
  | typeof NumberCustomField
  | typeof EmailCustomField
  | typeof LongTextCustomField
  | typeof DateTimeCustomField
  | typeof LinkCustomField

type CustomField = InstanceType<CustomFieldConstructor>

type CustomFieldTypeNameMapT = {
  [TypeNameT in GuestFieldType]: Extract<CustomFieldConstructor, new () => { type: TypeNameT }>
}

// @TODO: Make this procedural at some point?
// Idk this data doesn't move much so it's probably not necessary. It's cooler though.
const typeNameMap: CustomFieldTypeNameMapT = {
  [GuestFieldType.Checkbox]: CheckboxCustomField,
  [GuestFieldType.Number]: NumberCustomField,
  [GuestFieldType.List]: ListCustomField,
  [GuestFieldType.File]: FileCustomField,
  [GuestFieldType.Phone]: PhoneCustomField,
  [GuestFieldType.Text]: TextCustomField,
  [GuestFieldType.Email]: EmailCustomField,
  [GuestFieldType.LongText]: LongTextCustomField,
  [GuestFieldType.DateWithoutTimezone]: DateTimeCustomField,
  [GuestFieldType.Link]: LinkCustomField
}

export function getConstructorForFieldType<TypeNameT extends GuestFieldType>(
  type: TypeNameT
): CustomFieldTypeNameMapT[TypeNameT] {
  return typeNameMap[type]
}

type PlainObjectWithATypeString = { type: string; [_: string]: any }

namespace CustomField {
  export function fromPlainObject(obj: PlainObjectWithATypeString): CustomField {
    const safeTypeName = $enum(GuestFieldType).asValueOrThrow(obj.type)

    return plainToClass<CustomField, PlainObjectWithATypeString>(typeNameMap[safeTypeName], obj)
  }

  export function canConstructFromObject(obj: Record<string, any>): obj is PlainObjectWithATypeString {
    return 'type' in obj && typeof obj.type === 'string'
  }
}

export type GuestFieldOrigin = 'builtin' | 'custom'

export type GuestFieldKey = string

interface GuestFieldBase {
  type: GuestFieldType
  key: GuestFieldKey
  name: string
  origin: GuestFieldOrigin
  descriptionSlug?: string
}

interface BasicGuestField extends GuestFieldBase {
  type: Exclude<GuestFieldType, GuestFieldType.List | GuestFieldType.File | GuestFieldType.Text>
}

export interface ListGuestField extends GuestFieldBase {
  type: GuestFieldType.List
  list: ListFieldOption[]
  maxMultipleSelectList: number
}

interface FileGuestField extends GuestFieldBase {
  type: GuestFieldType.File
  fileFieldPossibleMimes: string[]
}

interface TextGuestField extends GuestFieldBase {
  type: GuestFieldType.Text
  pattern: string
}

export type GuestField = ListGuestField | FileGuestField | BasicGuestField | TextGuestField

export default CustomField
