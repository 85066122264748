

















import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import { FileUpload } from '@/components/features/fileUpload'
import type FileCustomField from '@/models/Event/GuestField/fieldTypes/File'
import { isNil } from 'lodash'
import isURL from 'validator/es/lib/isURL'

@Component({
  components: { FileUpload }
})
export default class GuestFieldFileInput extends Vue {
  @Prop({ required: true }) readonly field!: FileCustomField
  @Prop({}) readonly guestId?: string
  @VModel() readonly fieldValue!: string

  get fieldState(): boolean | null {
    if (isNil(this.fieldValue)) {
      return null
    } else if (typeof this.fieldValue === 'string') {
      return isURL(encodeURI(this.fieldValue))
    }
    return false
  }
}
