import type { AnonymousFilter } from '@/models/Event/Filter'
import type { EventLangCode } from '@/models/Event/Localized'
import { LocalizedPrimitive } from '@/models/Event/Localized'
import store from '@/store'
import { LoremIpsum } from 'lorem-ipsum'

type FormElementKind = 'field' | 'text'

interface FormElementBase {
  kind: FormElementKind
  infoText: LocalizedPrimitive<string>
  filter?: AnonymousFilter
  segmentsIds: string[]
}

export interface FormField extends FormElementBase {
  kind: 'field'
  key: string
  required: boolean
  locked: boolean
  isSignatureDraw: boolean
  hideListLimits: boolean
  clearIfConditionNotMatched: boolean
}

export interface FormText extends FormElementBase {
  kind: 'text'
}

export type FormElement = FormField | FormText

export function createFieldFormElement({
  key,
  required = false,
  locked = false
}: {
  key: string
  required?: boolean
  locked?: boolean
}): FormField {
  return {
    clearIfConditionNotMatched: false,
    hideListLimits: false,
    infoText: new LocalizedPrimitive<string>(),
    isSignatureDraw: false,
    key,
    kind: 'field',
    locked,
    required,
    segmentsIds: []
  }
}

const lorem = new LoremIpsum()

export function createTextFormElement(): FormText {
  const formText: FormText = {
    infoText: new LocalizedPrimitive<string>(),
    kind: 'text',
    segmentsIds: []
  }
  const websiteLanguages = [
    store.getters.event.mainLanguageCode,
    ...store.getters.event.modules_data.website.websiteTranslations
  ] as EventLangCode[]

  for (const lang of websiteLanguages) {
    formText.infoText[lang] = lorem.generateSentences(3)
  }
  return formText
}
