import { groupActivitiesRoute } from './groupActivities'
import { sponsorsRoutes } from './sponsors'
import { programmeSettingsRoutes } from './settings'
import type { ReadonlyFullRouteConfig } from '@/core'
import { networkingRoutes } from './networking'
import { matchmakingRoutes } from './matchmaking'

export const programmeRoutes: ReadonlyFullRouteConfig = [
  ...groupActivitiesRoute,
  ...matchmakingRoutes,
  ...sponsorsRoutes,
  ...programmeSettingsRoutes,
  ...networkingRoutes
]
