























































import type { GuestField } from '@/models/Event/GuestField'
import { deleteGuest } from '@/services/guest'
import { getSlaves } from '@/services/multiRegistration'
import axios from 'axios'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { ListFieldOption } from '@/models/Event/GuestField/fieldTypes/List'
import { DigiButton } from '@/components/ui/actions'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import AddAccompanistButton from '../addAccompanist/AddAccompanistButton.vue'
import DeleteAccompanistButton from './actions/DeleteAccompanistButton.vue'
import DissociateAccompanistButton from './actions/DissociateAccompanistButton.vue'
import type { ContactProfileModal } from '@/features/audience/contactProfile'

@Component({
  components: {
    DigiSimpleIconButton,
    DigiButton,
    ContactProfileModal: () =>
      import('@/features/audience/contactProfile').then(({ ContactProfileModal }) => ContactProfileModal),
    AddAccompanistButton,
    DeleteAccompanistButton,
    DissociateAccompanistButton
  }
})
export default class ContactAdditionalAttendeesTable extends Vue {
  @Prop({ required: true }) readonly master!: Guest

  @Ref() readonly accompanistDetailsModal!: ContactProfileModal

  slavesData: Guest[] = []
  isLoading: boolean = true

  // Get datas of fields that are selected in multiRegistration configuration
  get selectedFieldsData() {
    return this.$store.getters.orderedGuestFields.filter((field: GuestField) =>
      this.event.modules_data.multiRegistration.fieldsToDisplayInTableAccompanist.includes(field.key)
    )
  }

  get tableFields() {
    return [
      ...this.selectedFieldsData.map((field: GuestField) => {
        return {
          key: field.key,
          label: this.$t(field.name)
        }
      }),
      { key: 'details', label: '', class: 'minimalWidth' },
      { key: 'dissociateAccompanist', label: '', class: 'minimalWidth' },
      { key: 'removeBtn', label: '', class: 'minimalWidth' }
    ]
  }

  get hasAccompanists(): boolean {
    if (!this.master.masterRegistrationOf) {
      return false
    }
    return this.master.masterRegistrationOf.length > 0
  }

  get event() {
    return this.$store.getters.event
  }

  created() {
    return this.loadSlavesData()
  }

  saveGuestLocally(updatedGuest: Guest) {
    this.slavesData = this.slavesData.map((g) => {
      if (g._id !== updatedGuest._id) {
        return g
      } else {
        return updatedGuest
      }
    })
  }

  updateGuest(guest: Guest) {
    this.saveGuestLocally(guest)
    this.$emit('update:guest', guest)
  }

  openDetailsModal(guest: Guest) {
    this.accompanistDetailsModal.loadGuestAndOpenModal(guest)
  }

  async dissociateAccompanist(targetSlave: Guest) {
    // Extract res.data.slave as updatedSlave
    const updatedSlave = await axios
      .post<Guest>(`backoffice/events/${this.event._id}/guests/${targetSlave._id}/detachSlaveFromMaster`)
      .then((r) => r.data)
    // Notify ourselves and our parent of the updated slave
    // And remove superfluous data
    this.saveGuestLocally(updatedSlave)
    this.slavesData = this.slavesData.filter((slave) => slave._id !== targetSlave._id)
    this.updateGuest(updatedSlave)

    // Notify parent of updated master guest (removed one slave)
    this.$emit('update:guest', {
      ...this.master,
      masterRegistrationOf: this.master.masterRegistrationOf?.filter((id) => id !== targetSlave._id)
    })

    // Notify the user through UI
    this.$notify({
      text: this.$t('ACCOMPANIST_REMOVED'),
      type: 'success'
    })
  }

  async dissociateAndDeleteAccompanist(slave: Guest) {
    await this.dissociateAccompanist(slave)

    await deleteGuest(this.event._id, slave._id, false)
    this.$emit('deleteGuest', slave)
  }

  async associateAccompanist(slave: Guest) {
    // Extract updatedSlave and updatedMaster from res.data
    const {
      data: { slave: updatedSlave, master: updatedMaster }
    } = await axios.post(`backoffice/events/${this.event._id}/slave/${slave._id}/attachToMaster/${this.master._id}`)

    // Notify ourselves and our parent of the updated slave
    // And add new slave data
    this.saveGuestLocally(updatedSlave)
    this.slavesData.push(updatedSlave)
    this.updateGuest(updatedSlave)

    // Notify parent of updated master guest (removed one slave)
    this.$emit('update:guest', updatedMaster)

    // Notify the user through UI
    this.$notify({
      text: this.$t('ACCOMPANIST_ADDED'),
      type: 'success'
    })
  }

  async handleAccompanistCreated() {
    await this.loadSlavesData()
    this.$emit('accompanistCreated', this.master._id)
  }

  async loadSlavesData() {
    this.isLoading = true
    try {
      const slaves = await getSlaves(this.master._id, this.event._id)
      this.slavesData = slaves
    } finally {
      this.isLoading = false
    }
  }

  optionFromId(list: ListFieldOption[], id: string) {
    const option = list.find((o) => o._id === id)
    if (!option) {
      throw new Error('List field option not found')
    }
    return option
  }

  findValueFromKey(list: ListFieldOption[], keys: string[]) {
    if (keys) {
      const names = keys.map((key) => {
        return this.optionFromId(list, key).value
      })
      return names
    }
  }
}
