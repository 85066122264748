









import { Component, Prop, Watch } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import { countGuestGroupActivities, GuestPlanningMeetingFetcher } from '@/features/eventApp'
import ContactProfileTab from '../ui/ContactProfileTab.vue'
import type { DigiVerticalMenuItem } from '@/components/ui/verticalMenu'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: { ContactProfileTab }
})
export default class ContactProfileProgrammeTabContent extends Vue {
  @Prop({ required: true }) readonly item!: DigiVerticalMenuItem
  @Prop({ required: true }) readonly guest!: Guest

  scheduledMeetingCount: number | null = null
  meetingToConfirmCount: number | null = null

  @Watch('guest', { immediate: true })
  async onGuestChange() {
    this.scheduledMeetingCount = await GuestPlanningMeetingFetcher.instance.countPlannedMeetings(this.guest._id)
    this.meetingToConfirmCount = await GuestPlanningMeetingFetcher.instance.countUnscheduledMeetings(this.guest._id)
  }

  get storeEvent(): Event {
    const storeEvent = assertEvent(this.$store.state.event.event)

    return storeEvent
  }

  get groupActivityCount(): number {
    if (!this.guest.planning) return 0
    return countGuestGroupActivities(this.guest, this.storeEvent)
  }
}
