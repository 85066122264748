




































import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import { Component, Prop, Watch } from 'vue-property-decorator'
import type { GuestAppProfile, MeetingPossibleSlot, ScheduledMeeting } from '@/digiteventApi'
import { getAvailableTimeSlotsForMeeting } from '@/digiteventApi'
import { findMeetingTargetId } from './utils'
import { joinIfExist } from '../../../utils/string'

async function rescheduleMeeting({
  eventId,
  meetingId,
  newSlotId
}: {
  eventId: string
  meetingId: string
  newSlotId: string
}) {
  await axios.put(`/backoffice/events/${eventId}/matchmaking/meeting/${meetingId}/reschedule`, {
    newSlotId
  })
}

@Component({})
export default class RescheduleMeeting extends Vue {
  @Prop({ required: true }) readonly guestId!: string
  @Prop({ required: true }) readonly meeting!: ScheduledMeeting
  @Prop({ required: true }) readonly counterpartAppProfile!: GuestAppProfile

  active: boolean = false
  isFetchingSlots: boolean = false
  timeSlots: MeetingPossibleSlot[] | null = null
  pickedTimeSlot: MeetingPossibleSlot | null = null
  isRescheduling: boolean = false

  doReschedule(): void {
    this.active = true
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get hasAvailableSlots(): boolean {
    return this.timeSlots !== null && this.timeSlots.length > 0
  }

  @Watch('active')
  async onActiveChange(active: boolean): Promise<void> {
    if (active) {
      this.pickedTimeSlot = null
      await this.fetchPossibleTimes()
    }
  }

  async fetchPossibleTimes(): Promise<void> {
    this.isFetchingSlots = true
    this.timeSlots = null

    try {
      const targetId = findMeetingTargetId(this.meeting)
      this.timeSlots = await getAvailableTimeSlotsForMeeting({
        eventId: this.storeEvent._id,
        applicantId: this.meeting.applicantId,
        targetId
      })
    } finally {
      this.isFetchingSlots = false
    }
  }

  get matchmakingSessionsNames(): Map<string, string> {
    const map = new Map<string, string>()
    for (const eventActivity of this.storeEvent.modules_data.programme.eventActivities) {
      if (eventActivity.kind !== 'matchmakingSession') continue
      map.set(eventActivity._id, eventActivity.title)
    }
    return map
  }

  get matchmakingLocations(): Array<{ areaId: string; meetingPointId: string; text: string }> {
    const areas = this.storeEvent.modules_data.programme.matchmakingAreas
    const result: { areaId: string; meetingPointId: string; text: string }[] = []
    for (const area of areas) {
      for (const point of area.points) {
        result.push({
          areaId: area._id,
          meetingPointId: point._id,
          text: `${area.name} > ${point.name}`
        })
      }
    }
    return result
  }

  computeSlotLocation(slot: MeetingPossibleSlot): string {
    if (slot.isVisio) return this.$t('MATCHMAKING_MEETING_VISIO_LOCATION') as string
    const location = this.matchmakingLocations.find((loc) => loc.meetingPointId === slot.location?.meetingPointId)
    return location ? location.text : 'unknown'
  }

  get timeSlotOptions(): { text: string; value: MeetingPossibleSlot }[] {
    if (!this.timeSlots) return []
    const isMoreThanOneSession = this.matchmakingSessionsNames.size > 1
    return this.timeSlots.map((slot) => {
      let text = moment(slot.dateRange.start).format('l LT')
      if (isMoreThanOneSession) {
        text += ' - ' + this.matchmakingSessionsNames.get(slot.sessionId)
      }
      text += ' - ' + this.computeSlotLocation(slot)
      return {
        text,
        value: slot
      }
    })
  }

  get canConfirm(): boolean {
    return (
      !this.isFetchingSlots &&
      this.pickedTimeSlot !== null &&
      this.timeSlotOptions.some((opt) => opt.value === this.pickedTimeSlot)
    )
  }

  get counterPartFullName() {
    return joinIfExist([this.counterpartAppProfile.firstname, this.counterpartAppProfile.name], ' ')
  }

  async confirmReschedule(): Promise<void> {
    if (this.isRescheduling) throw new Error('Already rescheduling.')
    if (!this.pickedTimeSlot) throw new Error('Cannot confirm reschedule: No time slot was picked.')

    this.isRescheduling = true

    try {
      await rescheduleMeeting({
        eventId: this.storeEvent._id,
        meetingId: this.meeting._id,
        newSlotId: this.pickedTimeSlot.id
      })
      this.$emit('rescheduled')
      this.active = false
    } finally {
      this.isRescheduling = false
    }
  }
}
