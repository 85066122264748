import type { EventId } from '@/models/Event'
import type { GuestFieldType } from '@/models/Event/GuestField'
import type { GuestId, GuestSource } from '@/models/Guest'
import axios from 'axios'

export type ModificationContext = 'back' | 'api' | 'server' | 'checkin' | 'attendee' | 'mainGuest'

interface BaseEntry {
  date: Date
  updatedBy?: string
}

interface BasicModification {
  value: any
  fieldType: Exclude<GuestFieldType, GuestFieldType.List>
}

interface ListModification {
  value: string[]
  fieldType: GuestFieldType.List
}

export type Modification = BasicModification | ListModification

export type Modifications = Record<string, Modification>

export interface ModificationEntry extends BaseEntry {
  context: ModificationContext
  modifications: Modifications
}

export interface CreationEntry extends BaseEntry {
  creation: GuestSource
}

export interface GuestHistory {
  entries: ModificationEntry[]
  creation: CreationEntry
}

export async function getGuestHistory(eventId: EventId, guestId: GuestId): Promise<GuestHistory> {
  const res = await axios.get<GuestHistory>(`backoffice/events/${eventId}/guests/${guestId}/history`)
  return res.data
}
