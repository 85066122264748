import { Website } from './modules/Website'
import { Transform, Type } from 'class-transformer'
import type { MongoDb } from '../MongoDbTools'
import Identity from './Identity'
import type { FilterId } from './Filter'
import Filter from './Filter'
import { Programme } from './modules/Programme'
import type { GuestFieldKey, GuestField, CustomFieldId } from './GuestField'
import CustomFieldBase from './GuestField/CustomFieldBase'
import { PaymentModule } from './modules/Payment'
import EventMessagesData from './Messages'
import type { EventLangCode } from './Localized'
import CampaignsModule from './modules/Campaigns'
import RulesData from './RulesData'
import { ErrorWithExtras } from '@/utils/errorTypes'
import { TransformDateStringToDateObject } from '@/utils/classTransformerExtensions'
import MultiRegistrationModule from './modules/MultiRegistration'
import { BadgeStickersModule } from './modules/Badges/Stickers'
import type Export from './Export'
import States from './States'
import CheckinModule from './modules/Checkin'
import type { MixedSort } from '../commons/sort'
import type { AccessPass } from './modules/AccessPass'
import type { PDFBadgeModule } from './modules/PDFBadge'
import type { CertificateModule } from './modules/Certificate'
import Form, { FormNotFoundError } from './Form'
import { i18n } from '@/utils/i18n'
import type { NetworkingModule } from '@/models/Event/modules/Networking'
import type { ChatModuleData } from './modules/Chat'
import type { Front } from '@/models/Event/Front'
import type { DigiteventZone, EventPresenceType, EventTypology } from '@/core'
import type { Account } from '../Account'
import { portEventPagesToLang } from '@/digiteventApi'
import CustomField from './GuestField'
import type { Registration } from './Registration'

export type EventId = MongoDb.ObjectId

export interface ScanModule {}

export class ModulesData {
  @Type(() => Programme)
  programme!: Programme
  @Type(() => PaymentModule)
  payment!: PaymentModule
  @Type(() => MultiRegistrationModule)
  multiRegistration!: MultiRegistrationModule
  @Type(() => Website)
  website!: Website
  @Type(() => CampaignsModule)
  campaigns!: CampaignsModule
  networking!: NetworkingModule
  @Type(() => BadgeStickersModule)
  checkinStickerBadge!: BadgeStickersModule
  @Type(() => CheckinModule)
  checkin!: CheckinModule
  accessPass!: AccessPass
  scan!: ScanModule
  pdfBadge!: PDFBadgeModule
  certificate!: CertificateModule
  chat!: ChatModuleData
  registration!: Registration
}

export type EventStatus = 'demo' | 'active' | 'ended'

type EventTimeZone = string

export type CustomFontKey = 'primary' | 'secondary'

export type CustomFonts = {
  [key in CustomFontKey]?: string
}

export enum DeletionMethod {
  Manual = 'manual',
  Cron = 'cron'
}

class ReminderHistory {
  first?: Date

  second?: Date

  third?: Date
}

export class GuestDeletion {
  public done?: boolean

  public method?: DeletionMethod

  public deletionDate?: Date

  public unsubscribeReminder?: boolean

  public reminderHistory?: ReminderHistory
}

export class Event {
  _id!: EventId
  zone!: DigiteventZone
  label!: string
  @Type(() => Identity)
  identity!: Identity
  owner?: string | Account

  couponCode?: string
  status: EventStatus = 'demo'

  presenceType!: EventPresenceType
  eventTypology!: EventTypology

  guestsDeletion?: GuestDeletion

  @TransformDateStringToDateObject()
  eventDate!: Date
  @TransformDateStringToDateObject()
  eventEndDate!: Date
  timezone: EventTimeZone = 'Europe/Paris'

  calendar!: { description: string }

  //#region Custom field management
  @Type(() => CustomFieldBase)
  @Transform(({ value }) => (value !== undefined ? value.map(CustomField.fromPlainObject) : value))
  customFields: CustomField[] = []
  customFieldsSortOrder: MixedSort = []

  tryGetCustomFieldById(id: CustomFieldId): CustomField | null {
    return this.customFields.find((field) => field._id === id) ?? null
  }

  getCustomFieldById(id: CustomFieldId): CustomField {
    const result = this.customFields.find((field) => field._id === id)

    if (result) {
      return result
    } else {
      throw new ErrorWithExtras(`No custom field found with ID ${id}.`, { id })
    }
  }
  //#endregion

  //#region Guest field management
  guestFields: GuestField[] = []

  tryGetGuestFieldByKey(key: GuestFieldKey): GuestField | null {
    return this.guestFields.find((field) => field.key === key) ?? null
  }

  getGuestFieldByKey(key: GuestFieldKey): GuestField {
    const result = this.guestFields.find((field) => field.key === key)

    if (result) {
      return result
    } else {
      throw new ErrorWithExtras(`No guest field found with key ${key}`, { key })
    }
  }
  //#endregion

  importGuestsSources?: string[]

  // #region Filters
  @Type(() => Filter)
  filters: Filter[] = []

  filtersSort!: MixedSort

  tryGetFilterById(filterId: FilterId): Filter | null {
    return this.filters.find((filter) => filter._id === filterId) ?? null
  }

  getFilterById(filterId: FilterId): Filter {
    const found = this.filters.find((filter) => filter._id === filterId)

    if (found) {
      return found
    } else {
      throw new ErrorWithExtras(`No filter found for ID ${filterId}`, { filterId })
    }
  }
  // #endregion

  @Type(() => ModulesData)
  modules_data!: ModulesData

  @Type(() => EventMessagesData)
  messages!: EventMessagesData

  @Type(() => RulesData)
  rules!: RulesData

  customFonts?: CustomFonts
  mainLanguageCode!: EventLangCode
  eventLocation?: string
  exports!: Export[]
  allowEmailDuplicates?: boolean
  @Type(() => States)
  states!: States

  @Type(() => Form)
  forms!: Form[]

  front!: Front

  getFormById(targetId: NonNullable<Form['_id']>): Form {
    const foundForm = this.forms.find((form) => form._id === targetId)

    if (foundForm) {
      return foundForm
    } else {
      throw new FormNotFoundError(targetId)
    }
  }
  addNewForm(eventLanguageCode: EventLangCode, formName: string): Form {
    const websiteTranslations = this.modules_data.website?.websiteTranslations ?? []
    const newForm = new Form(websiteTranslations, eventLanguageCode, formName)
    this.forms.push(newForm)
    return newForm
  }

  async initializeDataForLanguage(baseLanguage: EventLangCode, targetLanguage: EventLangCode): Promise<void> {
    await portEventPagesToLang(this._id, baseLanguage, targetLanguage)

    await this.modules_data.website?.initializeDataForLanguage(baseLanguage, targetLanguage)
    await i18n.i18next.loadLanguages(targetLanguage)
    const fixedT = i18n.i18next.getFixedT(targetLanguage)

    for (const form of this.forms) {
      form.makeNameForLang(baseLanguage, targetLanguage)
      if (!form.terminateSignupLabel[targetLanguage]) form.terminateSignupLabel[targetLanguage] = fixedT('VALIDATE')
      if (!form.successMessage[targetLanguage]) form.successMessage[targetLanguage] = fixedT('FORM_SUBMISSION_MESSAGE')
    }
  }
}
