import type { ScheduledItemId } from '@/models/Guest/Planning/ScheduledItem'
import type { DateRange, EventActivityId, Programme } from '@/models/Event/modules/Programme'
import type Guest from '@/models/Guest'
import type { Event } from '@/models/Event'
import { getMandatoryGroupActivitiesOfGuest } from '../groupActivities'
import { i18n } from '@/utils/i18n'
import { GuestPlanningMeetingFetcher } from './GuestPlanningMeetingFetcher'
import type { PlannedMeeting } from '@/digiteventApi'

interface GuestPlanningItemBase {
  kind: 'groupActivity' | 'unavailability' | 'meeting'
  dateRange: DateRange
  title: string
}

interface GroupActivityPlanningItem extends GuestPlanningItemBase {
  kind: 'groupActivity'
  eventActivityId: EventActivityId
  scheduledItemId: ScheduledItemId | null
  hasVisio: boolean
  visioLink?: string
  isVisioEmbededInDigitevent: boolean
  description: string
}

export interface MeetingPlanningItem extends GuestPlanningItemBase {
  kind: 'meeting'
  eventActivityId: EventActivityId
  plannedMeeting: PlannedMeeting
  scheduledItemId: ScheduledItemId
  includedBreakDuration: number
  location?: string
  hasVisio: boolean
}

interface UnavailabilityPlanningItem extends GuestPlanningItemBase {
  kind: 'unavailability'
  scheduledItemId: ScheduledItemId
}

export type GuestPlanningItem = GroupActivityPlanningItem | UnavailabilityPlanningItem | MeetingPlanningItem

function computeGuestScheduledActivity(guest: Guest, event: Event): GroupActivityPlanningItem[] {
  const completePlanningGroupActivities: GroupActivityPlanningItem[] = []
  for (const activity of getMandatoryGroupActivitiesOfGuest(guest, event)) {
    completePlanningGroupActivities.push({
      kind: 'groupActivity',
      eventActivityId: activity._id,
      scheduledItemId: null,
      dateRange: activity.dateRange,
      title: activity.title,
      description: activity.description,
      hasVisio: activity.hasVisio,
      isVisioEmbededInDigitevent: activity.isVisioEmbededInDigitevent
    })
  }

  if (!guest.planning) return completePlanningGroupActivities
  for (const scheduledItem of guest.planning.scheduledItems) {
    if (scheduledItem.kind === 'groupActivity') {
      const activityDefinition = event.modules_data.programme.getGroupActivityById(scheduledItem.eventActivityId)

      completePlanningGroupActivities.push({
        kind: 'groupActivity',
        eventActivityId: scheduledItem.eventActivityId,
        scheduledItemId: scheduledItem._id,
        dateRange: activityDefinition.dateRange,
        title: activityDefinition.title,
        description: activityDefinition.description,
        hasVisio: activityDefinition.hasVisio,
        isVisioEmbededInDigitevent: activityDefinition.isVisioEmbededInDigitevent
      })
    }
  }
  return completePlanningGroupActivities
}

function computeGuestUnavailibilities(guest: Guest): UnavailabilityPlanningItem[] {
  if (!guest.planning) return []
  const unavailabilities: UnavailabilityPlanningItem[] = []
  for (const scheduledItem of guest.planning.scheduledItems) {
    if (scheduledItem.kind === 'unavailability') {
      unavailabilities.push({
        kind: 'unavailability',
        scheduledItemId: scheduledItem._id,
        dateRange: scheduledItem.dateRange,
        title: i18n.t('UNAVAILABILITY')
      })
    }
  }
  return unavailabilities
}

async function getGuestPlannedMeetings(guestId: string, programme: Programme): Promise<MeetingPlanningItem[]> {
  const plannedMeetings = await GuestPlanningMeetingFetcher.instance.getPlannedMeetings(guestId)
  if (!plannedMeetings) return []
  const planning: MeetingPlanningItem[] = []
  for (const plannedMeeting of plannedMeetings) {
    const activityDefinition = programme.getMatchmakingSessionById(plannedMeeting.matchmakingSessionId)
    planning.push({
      kind: 'meeting',
      eventActivityId: plannedMeeting.matchmakingSessionId,
      dateRange: [new Date(plannedMeeting.dateRange[0]), new Date(plannedMeeting.dateRange[1])],
      title: activityDefinition.title,
      plannedMeeting,
      hasVisio: activityDefinition.hasVisio,
      includedBreakDuration: activityDefinition.includedBreakDuration,
      scheduledItemId: plannedMeeting._id
    })
  }
  return planning
}

export async function computeGuestPlanning(guest: Guest, event: Event): Promise<GuestPlanningItem[]> {
  const meetings = await getGuestPlannedMeetings(guest._id, event.modules_data.programme)
  const planning: GuestPlanningItem[] = [
    ...computeGuestScheduledActivity(guest, event),
    ...computeGuestUnavailibilities(guest),
    ...meetings
  ]

  planning.sort((a, b) => a.dateRange[0].valueOf() - b.dateRange[0].valueOf())

  return planning
}

export function countGuestGroupActivities(guest: Guest, event: Event): number {
  const groupActivities = computeGuestScheduledActivity(guest, event)
  return groupActivities.length
}
