



















import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import DigiCard from '@/DigiCard.vue'
import type { MatchmakingMeetingArea } from '@/models/Event/modules/Programme'
import MatchmakingMeetingPointCard from './MatchmakingMeetingPointCard.vue'
import { DigiButton } from '@/components/ui/actions'
import MeetingPointCreationModal from './MeetingPointCreationModal.vue'

@Component({
  components: { MeetingPointCreationModal, DigiButton, MatchmakingMeetingPointCard, DigiCard }
})
export default class MatchmakingMeetingPointsEdition extends Vue {
  @Prop({ required: true }) readonly eventId!: string
  @Prop({ required: true }) readonly zone!: MatchmakingMeetingArea
  @Ref('meetingPointCreationModal') meetingPointCreationModal!: MeetingPointCreationModal

  openMeetingPointCreationModal() {
    this.meetingPointCreationModal.show()
  }
}
