import store from '@/store'
import type { Activity } from '@/models/Event/modules/Programme'
import type { ReadonlyFullRouteConfig } from '../../../core'

export const groupActivitiesRoute: ReadonlyFullRouteConfig = [
  {
    path: 'programme',
    name: 'event.website.programme',
    component: () => import('./views/GroupActivitiesList.vue'),
    meta: {
      name: 'PROGRAMME_GROUP_ACTIVITY_LIST_TITLE',
      keywords: 'FACTORY_PROGRAMME_KEYWORDS',
      iconName: 'calendar-event-line',
      searchable: true,
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.eventApp.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      },
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      renderingData: {
        get shouldDisplayPageHeader() {
          return (
            store.getters.event?.modules_data.programme.eventActivities.filter(
              (a: Activity) => a.kind === 'groupActivity'
            ).length > 0
          )
        }
      }
    }
  },
  {
    path: 'programme/groupActivities/:activityId',
    name: 'event.website.programme.groupActivities.edition',
    component: () => import('./views/GroupActivities/GroupActivityEdition/GroupActivityEdition.vue'),
    meta: {
      subPageOf: 'event.website.programme'
    }
  }
] as const
