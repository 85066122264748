



















import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import type { UnscheduledMeeting } from '@/digiteventApi'
import MeetingRequestAcceptationModal from './MeetingRequestAcceptationModal.vue'
import { ContactProfileModal } from '@/features/audience/contactProfile'
import type { GuestAppProfile } from '@/digiteventApi'
import { GuestAppProfileFetcher } from '../../services'
import { joinIfExist } from '@/utils/string'
import moment from 'moment/moment'

@Component({
  components: { ContactProfileModal, MeetingRequestAcceptationModal }
})
export default class MeetingRequestReceivedItem extends Vue {
  @Prop() readonly meeting!: UnscheduledMeeting
  @Prop() readonly guestId!: string
  @Ref() readonly meetingRequestAcceptationModal!: MeetingRequestAcceptationModal

  meetingApplicantAppProfile: GuestAppProfile | null = null

  @Watch('meeting', { immediate: true })
  async loadApplicantProfile() {
    this.meetingApplicantAppProfile = await GuestAppProfileFetcher.instance.getProfile(this.meeting.applicantId)
  }

  get applicantFullName() {
    if (!this.meetingApplicantAppProfile) return ''
    return joinIfExist([this.meetingApplicantAppProfile.firstname, this.meetingApplicantAppProfile.name], ' ')
  }

  get creationDate() {
    return moment(this.meeting.creationDate).fromNow()
  }

  async acceptMeetingRequest() {
    if (this.meeting.status !== 'pendingAcceptation') {
      throw new Error('Cannot accept a meeting request that is not pending acceptation')
    }
    this.meetingRequestAcceptationModal.acceptRequest(this.meeting)
  }

  openContactProfileModal() {
    this.$emit('open-profile', this.meeting.applicantId)
  }
}
