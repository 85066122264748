


















































































import type { MomentInput } from 'moment-timezone'
import moment from 'moment-timezone'
import { getGuestById } from '@/services/guest'
import { DigiSpinner } from '@/components/ui'
import UnavailabilityForm from './UnavailabilityForm.vue'
import ActivityForm from './ActivityForm.vue'
import RdvForm from './RdvForm.vue'
import MeetingRequests from './MeetingRequests/MeetingRequests.vue'
import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import type { BModal } from 'bootstrap-vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { computeGuestPlanning } from '@/features/eventApp'
import { DigiIconButton } from '@/components/ui/actions'
import PlanningItem from './PlanningItem/PlanningItem.vue'
import { GuestAppProfileFetcher, GuestPlanningMeetingFetcher } from '../services'
import type Guest from '@/models/Guest'
import type { GuestPlanningItem } from '../services/guestPlanning'
import type { UnscheduledMeeting } from '@/digiteventApi'
import type { PendingAcceptationMeeting } from '../../../digiteventApi'

@Component({
  components: {
    DigiIconButton,
    DigiSpinner,
    UnavailabilityForm,
    ActivityForm,
    RdvForm,
    MeetingRequests,
    PlanningItem
  },
  filters: {
    timeAgo(isoDate: MomentInput) {
      return moment(isoDate).fromNow()
    }
  }
})
export default class ContactPlanningEditor extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  @Ref() readonly addModal!: BModal

  creatingType: 'meeting' | 'group_activity' | 'unavailability' | null = null
  reloadingGuestData: boolean = false
  planning: GuestPlanningItem[] | null = null
  unscheduledMeetings: UnscheduledMeeting[] | null = null
  isLoading: boolean = false

  mounted() {
    GuestPlanningMeetingFetcher.initialize({ eventId: this.storeEvent._id })
    GuestAppProfileFetcher.initialize({ eventId: this.storeEvent._id })
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get matchmakingSessions(): MatchmakingSession[] {
    return Array.from(this.storeEvent.modules_data.programme.matchMakingSessions())
  }

  get planningDataByDate(): { date: string; planning: GuestPlanningItem[] }[] {
    if (!this.planning) return []
    const groups = new Map<string, GuestPlanningItem[]>()

    this.planning.forEach((plan) => {
      const date: string = moment(plan.dateRange[0]).format('YYYY-MM-DDT12:00:00Z')

      if (groups.has(date)) {
        groups.get(date)!.push(plan)
      } else {
        groups.set(date, [plan])
      }
    })

    return Array.from(groups.entries())
      .sort(([date1], [date2]) => date1.localeCompare(date2))
      .map(([date, planning]) => ({
        date: moment(date).format('l'),
        planning: planning.sort((planItem1, planItem2) =>
          moment(planItem1.dateRange[0]).diff(moment(planItem2.dateRange[0]))
        )
      }))
  }

  get hasAnyUnscheduledMeeting(): boolean {
    return this.unscheduledMeetings ? this.unscheduledMeetings.length > 0 : false
  }

  get hasAnythingScheduled(): boolean {
    return this.planning ? this.planning.length > 0 : false
  }

  get hasEmptyPlanning() {
    return !this.hasAnyUnscheduledMeeting && !this.hasAnythingScheduled
  }

  get modalTitle(): string {
    switch (this.creatingType) {
      case 'meeting':
        return this.$t('REGISTER_MATCHMAKING_SESSION')

      case 'group_activity':
        return this.$t('REGISTER_GROUP_ACTIVITY')

      case 'unavailability':
        return this.$t('UNAVAILABILITY')

      default:
        return ''
    }
  }

  @Watch('guest', { immediate: true })
  async loadGuestData(): Promise<void> {
    if (this.isLoading) return

    this.isLoading = true

    try {
      this.planning = await computeGuestPlanning(this.guest, this.storeEvent)
      this.unscheduledMeetings = await GuestPlanningMeetingFetcher.instance.getUnscheduledMeetings(this.guest._id)
    } finally {
      this.isLoading = false
    }
  }

  async enforceReloadOfGuests(guestIds: string[]): Promise<void> {
    if (this.reloadingGuestData === true) {
      console.warn(
        'Multiple reloads of guest data are being requested simultaneously. This will likely not break but may cause some UI weirdness.'
      )
    }

    this.reloadingGuestData = true

    try {
      await Promise.all(
        guestIds.map(async (guestId) => {
          this.emitGuestUpdate(await getGuestById(this.storeEvent._id, guestId))
        })
      )
    } finally {
      this.reloadingGuestData = false
    }
  }

  emitGuestUpdate(guest: Guest): void {
    this.$emit('update:guest', guest)
  }

  async deletePlanItem(planningItem: GuestPlanningItem): Promise<void> {
    if (planningItem.kind === 'meeting') {
      const scheduledItem = this.planning?.find((item) => item.scheduledItemId === planningItem.scheduledItemId)

      if (!scheduledItem || scheduledItem.kind !== 'meeting') {
        throw new Error('Could not find scheduled meeting with the specified ID.')
      }

      await this.enforceReloadOfGuests(scheduledItem.plannedMeeting.guests.map((guest) => guest.guestId))
    } else {
      await this.enforceReloadOfGuests([this.guest._id])
    }
  }

  async onPlanItemRescheduled(planningItem: GuestPlanningItem) {
    if (planningItem.scheduledItemId) {
      const scheduledItem = this.planning?.find((item) => item.scheduledItemId === planningItem.scheduledItemId)

      if (!scheduledItem) {
        throw new Error('Plan item references nonexistent schedule item ID.')
      }
      if (scheduledItem.kind === 'meeting') {
        await this.enforceReloadOfGuests(scheduledItem.plannedMeeting.guests.map((guest) => guest.guestId))
      } else {
        await this.enforceReloadOfGuests([this.guest._id])
      }
    }
  }

  async onMeetingRequestsReload(request: PendingAcceptationMeeting) {
    await this.enforceReloadOfGuests(request.guests.map((guest) => guest.guestId))
  }

  async handleAdditionDone(payload: { guest: Guest }) {
    const newGuest: Guest = payload.guest
    let hasPropagatedNewGuest = false

    if (newGuest) {
      this.emitGuestUpdate(newGuest)
      hasPropagatedNewGuest = true
    }

    this.closeAdditionModal()

    await this.$nextTick()

    if (!hasPropagatedNewGuest) {
      await this.enforceReloadOfGuests([this.guest._id])
    }
  }

  async handleAddMeeting(meetingId: string) {
    const meeting = this.planning?.find((item) => item.scheduledItemId === meetingId)
    if (!meeting || meeting.kind !== 'meeting') {
      throw new Error('Could not find scheduled meeting with the specified ID.')
    }
    await this.enforceReloadOfGuests(meeting.plannedMeeting.guests.map((guest) => guest.guestId))
  }

  closeAdditionModal() {
    this.addModal.hide()
  }
}
