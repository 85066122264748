import axios from 'axios'
import type { GuestAppProfile } from '@/digiteventApi'
import { InMemoryObjectCache } from '@/lib/InMemoryObjectCache'

type ProfileOrPromise = GuestAppProfile | Promise<GuestAppProfile>

export class GuestAppProfileFetcher {
  // eslint-disable-next-line no-useless-constructor
  constructor(private eventId: string) {}
  // This is an in-memory cache. After page is refreshed, cache is cleared.
  private cache = new InMemoryObjectCache()

  async getProfile(guestId: string): Promise<GuestAppProfile | null> {
    if (!guestId) {
      throw new Error('guestId is required')
    }
    const cached = this.cache.get<ProfileOrPromise>(guestId)
    if (cached) {
      // May be profile or promise to profile
      return cached
    }

    const fetchPromise = this.fetchProfile(guestId).then(
      (profile) => {
        const ttlSeconds = profile ? 60 * 60 : 5 // 1h or 5s
        this.cache.set(guestId, profile, { ttlSeconds })
        return profile
      },
      (err) => {
        this.cache.invalidate(guestId)
        return Promise.reject(err)
      }
    )
    this.cache.set(guestId, fetchPromise, { ttlSeconds: 60 * 60 }) // 1h
    return fetchPromise
  }

  private async fetchProfile(guestId: string): Promise<GuestAppProfile | null> {
    const { status, data } = await axios.get<{ appProfile?: GuestAppProfile }>(
      `/site/events/${this.eventId}/guests/${guestId}/appProfile`,
      {
        validateStatus: (status) => [200, 404].includes(status)
      }
    )
    if (status === 404) {
      return null
    }
    const profile = data.appProfile
    if (!profile) {
      throw new Error('Profile not found in response')
    }
    return profile
  }

  private static _instance: GuestAppProfileFetcher | null = null
  public static get instance(): GuestAppProfileFetcher {
    if (!GuestAppProfileFetcher._instance) {
      throw new Error('GuestAppProfileFetcher is not initialized')
    }
    return GuestAppProfileFetcher._instance
  }

  public static initialize({ eventId }: { eventId: string }) {
    GuestAppProfileFetcher._instance = new GuestAppProfileFetcher(eventId)
  }
}
