import type { PlanningMeeting, MeetingGuest } from '@/digiteventApi'

export function findMeetingTargetId(meeting: PlanningMeeting): string {
  const target = meeting.guests.find((guest) => guest.guestId !== meeting.applicantId)
  if (!target) {
    throw new Error('No target found in meeting')
  }
  return target.guestId
}

export function findMeetingCounterpart(meeting: PlanningMeeting, guestId: string): MeetingGuest {
  const counterpart = meeting.guests.find((guest) => guest.guestId !== guestId)
  if (!counterpart) {
    throw new Error('No counterpart found in meeting')
  }
  return counterpart
}
