








import { Vue, Component } from 'vue-property-decorator'
import type { Event } from '@/models/Event'
import { assertEvent } from '@/services/storeEvent'
import type { MatchmakingMeetingArea } from '@/models/Event/modules/Programme'
import MatchmakingAreaEditionPart from './MatchmakingAreaEditionPart.vue'
import MatchmakingMeetingPointsEdition from '../meetingPoints/MatchmakingMeetingPointsEdition.vue'
import BackButton from '../../../../../components/features/BackButton.vue'
import MatchmakingPageHeader from '../../ui/MatchmakingPageHeader.vue'

@Component({
  components: { MatchmakingPageHeader, BackButton, MatchmakingMeetingPointsEdition, MatchmakingAreaEditionPart }
})
export default class MatchmakingZoneView extends Vue {
  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get area(): MatchmakingMeetingArea {
    const zoneId = this.$route.params.zoneId
    if (!zoneId) {
      throw new Error('No zoneId in url')
    }
    const areas = this.storeEvent.modules_data.programme.matchmakingAreas
    const area = areas.find((zone) => zone._id === zoneId)
    if (!area) {
      throw new Error('Zone not found')
    }
    return area
  }
}
