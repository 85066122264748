




import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import type LongTextCustomField from '@/models/Event/GuestField/fieldTypes/LongText'

@Component({})
export default class GuestFieldLongTextInput extends Vue {
  @VModel() readonly fieldValue!: string
  @Prop({ required: true }) readonly field!: LongTextCustomField
}
