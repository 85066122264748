

























import { Vue, Component, Prop, VModel } from 'vue-property-decorator'
import type { EventLangCode } from '@/models/Event/Localized'
import { getEventLangCodeSlug } from '@/models/Event/Localized'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import Editor from '@tinymce/tinymce-vue'
import { WysiwygEditor } from '@/components/features/wysiwyg'
import SanitizedHtmlRenderer from '@/components/features/SanitizedHtmlRenderer.vue'

@Component({
  components: { SanitizedHtmlRenderer, WysiwygEditor, Editor, DigiSimpleIconButton }
})
export default class TranslationsInputItem extends Vue {
  @VModel() readonly translation!: unknown
  @Prop({ required: true }) readonly language!: EventLangCode
  @Prop() readonly isEditor!: boolean

  isEditorEditable = false

  getEventLangCodeName(lang: EventLangCode): string {
    const slug = getEventLangCodeSlug(lang)
    return this.$t(slug) as string
  }
}
