

























import { DigiFormLayout } from '@/components/ui/form/formLayouts'
import { DigiFormGroup } from '@/components/ui/form/formGroup'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { type MatchmakingSession } from '@/models/Event/modules/Programme'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'

import { DigiButton } from '@/components/ui/actions'
import { DigiFormModal2 } from '@/components/ui/form/formLayouts'
import type { MatchmakingMeetingPoint } from '@/models/Event/modules/Programme'
import { updateMatchmakingMeetingPoint } from '@/digiteventApi'
import type { DigiFormGroupItem } from '@/components/ui/form/formGroup'
import MeetingPointGuestSelector from './MeetingPointGuestSelector.vue'
import { type AssignableGuest, getMeetingPointAssignee } from '@/digiteventApi'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    MeetingPointGuestSelector,
    DigiFormModal2,
    DigiButton,
    DigiFormLayout,
    DigiFormGroup
  }
})
export default class  MeetingPointEditionModal extends Vue {
  @Prop ({ required: true }) readonly zoneId!: string
  @Prop ({ required: true }) readonly initialPoint!: MatchmakingMeetingPoint

  isOpen = false

  isUpdating = false
  isLoadingMeetingPoint = false
  activity: MatchmakingSession | undefined

  name = ''
  assignedGuest: AssignableGuest | null = null
  initialAssignedGuest: AssignableGuest | null = null

  async show() {
    this.isOpen = true

    this.isLoadingMeetingPoint = true
    try {
      const { assignee, meetingPoint } = await getMeetingPointAssignee({
        eventId: this.storeEvent._id,
        areaId: this.zoneId,
        pointId: this.initialPoint._id
      })
      this.assignedGuest = cloneDeep(assignee) ?? null
      this.initialAssignedGuest = assignee ?? null
      this.name = meetingPoint.name
    } finally {
      this.isLoadingMeetingPoint = false
    }
  }


  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get formItems(): DigiFormGroupItem[] {
    return [
      { key: 'name', label: this.$t('MATCHMAKING_CREATE_MEETING_POINT_NAME_LABEL'), validationRules: { required: true } },
      { key: 'assignee', label: this.$t('MATCHMAKING_CREATE_MEETING_POINT_TYPE_LABEL'), displayMode: 'rows'},
    ]
  }

  get hasModifications(): boolean {
    return this.name !== this.initialPoint.name || this.assignedGuest?._id !== this.initialAssignedGuest?._id
  }

  get isLoading(): boolean {
    return this.isUpdating || this.isLoadingMeetingPoint
  }


  onHideModal() {
    this.isOpen = false
  }

  async updatePoint(): Promise<void> {
    this.isUpdating = true
    try {
      await updateMatchmakingMeetingPoint({
        eventId: this.storeEvent._id,
        zoneId: this.zoneId,
        pointId: this.initialPoint._id,
        name: this.name,
        assignedGuestId: this.assignedGuest?._id ?? null
      })
      await this.$store.dispatch('reloadCurrentEvent')
      this.isOpen = false
    } finally {
      this.isUpdating = false
    }
  }
}
