



































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { type AssignableGuest } from '@/digiteventApi'
import { DigiCardItemInformation } from '@/components/ui/cardItem'
import { joinIfExist } from '@/utils/string'
import { DigiSimpleIconButton } from '@/components/ui/actions'
import { getAssignableGuests } from '@/digiteventApi'
import MeetingPointAssignedGuestItem from './MeetingPointAssignedGuestItem.vue'
import { ContactProfileModal } from '../../../../audience/contactProfile'

type MeetingPointType = 'assigned' | 'unassigned'
@Component({
  components: { ContactProfileModal, MeetingPointAssignedGuestItem, DigiSimpleIconButton, DigiCardItemInformation }
})
export default class MeetingPointGuestSelector extends Vue {
  @Prop({ required: true }) readonly eventId!: string
  @Prop({ required: true }) readonly assignedGuest!: AssignableGuest | undefined

  @Ref('contactProfileModal') contactProfileModal!: ContactProfileModal
  @Ref('searchInput') searchInput!: HTMLInputElement

  isLoading = false
  assignableGuests: AssignableGuest[] = []
  search = ''
  meetingPointType : MeetingPointType= 'unassigned'

  created() {
    if(this.assignedGuest) {
      this.meetingPointType = 'assigned'
    } else {
      this.meetingPointType = 'unassigned'
    }
  }


  @Watch('meetingPointType')
  onShouldShowSearchChange() {
    if(this.meetingPointType === 'unassigned') {
      this.updateAssignedGuest(null)
    } else {
      if(!this.assignedGuest) {
        this.$nextTick(() => {
          this.searchInput.focus()
        })
      }
    }
  }

  @Watch('search')
  async onSearchChange() {
    if(this.search === '') {
      this.assignableGuests = []
      return
    }
    if(this.search.length < 2) {
      return
    }
    this.isLoading = true
    try {
      this.assignableGuests = await getAssignableGuests({
        eventId: this.eventId,
        search: this.search
      })
    } finally {
      this.isLoading = false
    }
  }

  get selectTypeOptions() : { value: MeetingPointType, text: string }[] {
    return [
      { value: "unassigned", text: this.$t('MEETING_POINT_GUEST_SELECTOR_SELECT_TYPE_UNASSIGNED') },
      { value: "assigned", text: this.$t('MEETING_POINT_GUEST_SELECTOR_SELECT_TYPE_ASSIGNED') }
    ]
  }

  get assignedGuestFullName():string {
      return joinIfExist([this.assignedGuest?.firstname, this.assignedGuest?.name], ' ')
  }

  updateAssignedGuest(guest: AssignableGuest | null) {
    this.$emit('update:assignedGuest', guest)
    this.search = ''
    if(guest) {
      this.meetingPointType = 'assigned'
    } else {
      this.meetingPointType = 'unassigned'
    }
  }
}
