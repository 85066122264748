import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import type { EventId } from '@/models/Event'
import type { EventActivityId, MatchmakingOptions, MatchmakingSession } from '@/models/Event/modules/Programme'
import type { MatchmakingQuota, MatchmakingRule } from '@/models/Event/modules/Programme'
import type {
  CancelledMeeting,
  DeclinedMeeting,
  MeetingStatus,
  PendingAcceptationMeeting,
  ScheduledMeeting
} from './meetings'
import type { MatchmakingMeetingPoint } from '../../models/Event/modules/Programme'

export enum StatsScheduledMeetingStatus {
  Upcoming = 'upcoming',
  Ended = 'ended',
  EndedChecked = 'endedChecked',
  InProgress = 'inProgress',
  InProgressChecked = 'inProgressChecked',
  AwaitingConnection = 'awaitingConnection',
  Unhonored = 'unhonored',
  Canceled = 'canceled'
}

export enum StatsUnscheduledMeetingStatus {
  Declined = 'declined',
  PendingAcceptation = 'pendingAcceptation'
}

export type StatsMeetingStatus = StatsScheduledMeetingStatus | StatsUnscheduledMeetingStatus

export type MatchmakingSessionStats = Record<MeetingStatus, number>

export async function createMatchmakingSession(
  eventId: string,
  title: string
): Promise<{ matchmakingSessionId: string }> {
  const result = await axios.post(`/events/${eventId}/programme/matchmaking/sessions`, { title })
  return result.data
}

export async function updateMatchmakingSessionInfos(
  eventId: string,
  sessionId: string,
  infos: Partial<
    Pick<MatchmakingSession, 'title' | 'areaIds' | 'meetingDuration' | 'includedBreakDuration' | 'hasVisio'>
  >
): Promise<void> {
  await axios.patch(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/infos`, { infos })
}

export async function deleteMatchmakingSession(eventId: string, sessionId: string) {
  await axios.delete(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}`)
}

export async function fetchMatchmakingSessionStats(
  eventId: EventId,
  loadFromCache: boolean = true
): Promise<MatchmakingSessionStats> {
  const axiosOptions: AxiosRequestConfig = {
    method: 'get',
    url: `backoffice/${eventId}/matchmaking/stats`,
    params: {
      loadFromCache
    }
  }
  const res = await axios.request<MatchmakingSessionStats>(axiosOptions)

  return res.data
}

export async function fetchScheduledMeetings({
  eventId,
  page,
  descending,
  limit,
  sortKey,
  status
}: {
  eventId: string
  status: StatsScheduledMeetingStatus
  page: number
  limit: number
  sortKey: 'date'
  descending: boolean
}): Promise<{
  meetings: Array<ScheduledMeeting | CancelledMeeting>
  meetingCount: number
}> {
  const res = await axios.get(`backoffice/${eventId}/matchmaking/scheduledMeetings`, {
    params: {
      status,
      page,
      limit,
      sortKey,
      descending
    }
  })
  return res.data
}

export async function fetchUnscheduledMeetings({
  eventId,
  status,
  page,
  limit,
  sortKey,
  descending
}: {
  eventId: string
  status: StatsUnscheduledMeetingStatus
  page: number
  limit: number
  sortKey: 'date'
  descending: boolean
}): Promise<{
  meetings: Array<PendingAcceptationMeeting | DeclinedMeeting>
  meetingCount: number
}> {
  const res = await axios.get(`backoffice/${eventId}/matchmaking/unscheduledMeetings`, {
    params: {
      status,
      page,
      limit,
      sortKey,
      descending
    }
  })
  return res.data
}

export type MeetingPossibleSlot = {
  dateRange: { start: string; end: string }
  isVisio: boolean
  includedBreakDuration: number
  sessionId: string
  location?: {
    areaId: string
    meetingPointId: string
  }
  id: string
}

export async function getAvailableTimeSlotsForMeeting({
  eventId,
  applicantId,
  targetId
}: {
  eventId: string
  applicantId: string
  targetId: string
}): Promise<MeetingPossibleSlot[]> {
  const { data } = await axios.get<{
    meetingPossibleSlots: MeetingPossibleSlot[]
  }>(`/events/${eventId}/matchmaking/possibleSlots`, {
    params: {
      applicantId,
      targetId
    }
  })
  return data.meetingPossibleSlots
}

export async function cancelMeeting(eventId: EventId, meetingId: EventActivityId): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/planning/meeting/${meetingId}/cancelScheduledMeeting`)
}

export async function removeNonMeetingScheduledItem(
  eventId: string,
  guestId: string,
  scheduledItemId: string
): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/guest/${guestId}/planning/scheduledItem/${scheduledItemId}/remove`)
}

export async function updateMatchmakingSettings(eventId: string, payload: Partial<MatchmakingOptions>): Promise<void> {
  await axios.patch(`/events/${eventId}/programme/matchmaking/settings`, { matchmakingSettings: payload })
}

export async function createMatchmakingQuota(eventId: string, quota: MatchmakingQuota) {
  await axios.post(`/events/${eventId}/programme/matchmaking/quota`, { quota })
}

export async function updateMatchmakingQuota(eventId: string, quotaId: string, quota: MatchmakingQuota) {
  await axios.put(`/events/${eventId}/programme/matchmaking/quota/${quotaId}`, { quota })
}

export async function deleteMatchmakingQuota(eventId: string, quotaId: string) {
  await axios.delete(`/events/${eventId}/programme/matchmaking/quota/${quotaId}`)
}

export async function createMatchmakingRule({
  eventId,
  rule,
  sessionId
}: {
  eventId: string
  rule: Omit<MatchmakingRule, '_id'>
  sessionId: string
}) {
  await axios.post(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/rule`, { rule })
}

export async function updateMatchmakingRule({
  eventId,
  rule,
  sessionId,
  ruleId
}: {
  eventId: string
  rule: MatchmakingRule
  sessionId: string
  ruleId: string
}) {
  await axios.put(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/rule/${ruleId}`, { rule })
}

export async function deleteMatchmakingRule({
  eventId,
  sessionId,
  ruleId
}: {
  eventId: string
  ruleId: string
  sessionId: string
}) {
  await axios.delete(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/rule/${ruleId}`)
}

export async function createMatchmakingDateRange({
  eventId,
  dateRange,
  sessionId
}: {
  eventId: string
  dateRange: Record<string, any>
  sessionId: string
}) {
  await axios.post(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/dateRange`, {
    dateRange
  })
}

export async function updateMatchmakingDateRange({
  eventId,
  dateRange,
  sessionId,
  dateRangeIndex
}: {
  eventId: string
  dateRange: Record<string, any>
  sessionId: string
  dateRangeIndex: number
}) {
  await axios.put(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/dateRange/${dateRangeIndex}`, {
    dateRange
  })
}

export async function deleteMatchmakingDateRange({
  eventId,
  sessionId,
  dateRangeIndex
}: {
  eventId: string
  dateRangeIndex: number
  sessionId: string
}) {
  await axios.delete(`/events/${eventId}/programme/matchmaking/sessions/${sessionId}/dateRange/${dateRangeIndex}`)
}

export async function scheduleMatchmakingMeeting({
  eventId,
  matchmakingObjectId,
  applicantId,
  targetId,
  slotId,
  adminId
}: {
  eventId: string
  matchmakingObjectId: string
  applicantId: string
  targetId: string
  slotId: string
  adminId: string
}) {
  const result = await axios.post<{
    meetingId: string
  }>(`/backoffice/events/${eventId}/matchmaking/${matchmakingObjectId}/scheduleMeeting`, {
    applicantId,
    targetId,
    slotId,
    adminId
  })
  return result.data
}

export async function removeMeetingRequestFrom({
  eventId,
  meetingRequestId
}: {
  eventId: string
  meetingRequestId: string
}) {
  await axios.put(`/backoffice/events/${eventId}/matchmaking/remove/${meetingRequestId}`)
}

export async function scheduleMeetingRequestFromBo({
  eventId,
  meetingRequestId,
  slotId,
  sessionId
}: {
  eventId: string
  meetingRequestId: string
  slotId: string
  sessionId: string
}) {
  await axios.put(`/backoffice/events/${eventId}/matchmaking/acceptMeetingRequest/${meetingRequestId}`, {
    slotId,
    sessionId
  })
}

export async function createMatchmakingMeetingZone({ eventId, name }: { eventId: string; name: string }) {
  const result = await axios.post<{ zoneId: string }>(`/backoffice/${eventId}/matchmaking/areas`, { name })
  return result.data
}

export async function updateMatchmakingMeetingZone({
  eventId,
  zoneId,
  name
}: {
  eventId: string
  zoneId: string
  name: string
}) {
  await axios.put(`/backoffice/${eventId}/matchmaking/areas/${zoneId}`, { name })
}

export async function deleteMatchmakingMeetingZone({ eventId, zoneId }: { eventId: string; zoneId: string }) {
  await axios.delete(`/backoffice/${eventId}/matchmaking/areas/${zoneId}`)
}

export type AssignableGuest = {
  _id: string
  firstname?: string
  name: string
}

export async function getAssignableGuests({
  search,
  eventId
}: {
  search: string
  eventId: string
}): Promise<AssignableGuest[]> {
  const res = await axios.get<{
    guests: AssignableGuest[]
  }>(`/backoffice/${eventId}/matchmaking/assignableGuests`, {
    params: {
      search
    }
  })
  return res.data.guests
}

export async function getMeetingPointAssignee({
  eventId,
  areaId,
  pointId
}: {
  eventId: string
  areaId: string
  pointId: string
}) {
  const result = await axios.get<{
    meetingPoint: MatchmakingMeetingPoint
    assignee: AssignableGuest | undefined
  }>(`/backoffice/${eventId}/matchmaking/areas/${areaId}/points/${pointId}`)
  return result.data
}

export async function createMatchmakingMeetingPoint({
  eventId,
  zoneId,
  assignedGuestId,
  name
}: {
  eventId: string
  zoneId: string
  name: string
  assignedGuestId: string | null
}): Promise<{ pointId: string }> {
  const result = await axios.post(`/backoffice/${eventId}/matchmaking/areas/${zoneId}/points`, {
    name,
    assignedGuestId
  })
  return result.data
}

export async function updateMatchmakingMeetingPoint({
  eventId,
  zoneId,
  pointId,
  name,
  assignedGuestId
}: {
  eventId: string
  zoneId: string
  pointId: string
  name: string
  assignedGuestId: string | null
}) {
  await axios.put(`/backoffice/${eventId}/matchmaking/areas/${zoneId}/points/${pointId}`, {
    name,
    assignedGuestId
  })
}

export async function deleteMatchmakingMeetingPoint({
  eventId,
  zoneId,
  pointId
}: {
  eventId: string
  zoneId: string
  pointId: string
}) {
  await axios.delete(`/backoffice/${eventId}/matchmaking/areas/${zoneId}/points/${pointId}`)
}
