
























import moment from 'moment-timezone'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import axios from 'axios'
import { DigiButton } from '@/components/ui/actions'
import { Component, Prop } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { GroupActivity } from '@/models/Event/modules/Programme'
import type { Activity } from '@/models/Event/modules/Programme'
import type GroupActivitySchedule from '@/models/Guest/Planning/ScheduledItem/GroupActivity'
import { matchSomeFilters } from '@digitevent/filterutils'
import type { ScheduledItem } from '@/models/Guest/Planning/ScheduledItem'

function isGroupActivity(item: Activity): item is GroupActivity {
  return item.kind === 'groupActivity'
}

function isGroupActivitySchedule(item: ScheduledItem): item is GroupActivitySchedule {
  return item.kind === 'groupActivity'
}

@Component({
  components: {
    DigiButton
  }
})
export default class ActivityForm extends Vue {
  @Prop({ required: true }) readonly guest!: Guest
  isSubmitting = false
  selectedActivityId: string | null = null

  get storeEvent(): Event {
    const storeEvent = assertEvent(this.$store.state.event.event)
    return storeEvent
  }

  get programmeModule() {
    return this.storeEvent.modules_data.programme
  }

  get eventGroupActivities(): GroupActivity[] {
    const groupActivities = []
    for (const activity of this.programmeModule.eventActivities) {
      if (isGroupActivity(activity)) {
        groupActivities.push(activity)
      }
    }
    return groupActivities
  }
  get activityOptions() {
    return this.eventGroupActivities.map((act) => ({
      value: act._id,
      text: `${act.title} (${moment(act.dateRange[0]).format('l - LT')})`,
      disabled: this.isGuestAlreadyInActivity(act)
    }))
  }

  get isGuestElligibleToSelectedActivity(): boolean {
    if (!this.selectedActivityId) {
      return true
    }
    const selectedActivity = this.eventGroupActivities.find((activity) => activity._id === this.selectedActivityId)
    if (!selectedActivity) {
      throw new Error(`Activity ${this.selectedActivityId} not found`)
    }
    return matchSomeFilters(
      selectedActivity.eligibleSegmentIds.map((id) => this.storeEvent.getFilterById(id)),
      this.guest
    )
  }

  isGuestAlreadyInActivity(activity: GroupActivity): boolean {
    const isAlreadyRegistered = this.guest.planning?.scheduledItems.some(
      (item) => isGroupActivitySchedule(item) && item.eventActivityId === activity._id
    )
    return isAlreadyRegistered ?? false
  }
  async submit() {
    this.isSubmitting = true

    try {
      const guest = await axios.post(
        `backoffice/events/${this.$store.getters.eventId}/guest/${this.guest._id}/planning/groupActivity/${this.selectedActivityId}/register`
      )
      this.$emit('done', guest)
    } finally {
      this.isSubmitting = false
    }
  }
}
