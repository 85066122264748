import axios from 'axios'
import type { PlannedMeeting, UnscheduledMeeting } from '@/digiteventApi'
import { InMemoryObjectCache } from '@/lib/InMemoryObjectCache'

type PlannedMeetingsOrPromise = Array<PlannedMeeting> | Promise<Array<PlannedMeeting>>
type UnscheduledMeetingsOrPromise = Array<UnscheduledMeeting> | Promise<Array<UnscheduledMeeting>>

export class GuestPlanningMeetingFetcher {
  // eslint-disable-next-line no-useless-constructor
  constructor(private eventId: string) {}
  // This is an in-memory cache. After page is refreshed, cache is cleared.
  private plannedMeetingsCache = new InMemoryObjectCache()
  private unscheduledMeetingsCache = new InMemoryObjectCache()

  async getPlannedMeetings(guestId: string): Promise<Array<PlannedMeeting> | null> {
    if (!guestId) {
      throw new Error('guestId is required')
    }
    const cached = this.plannedMeetingsCache.get<PlannedMeetingsOrPromise>(guestId)
    if (cached) {
      // May be profile or promise to profile
      return cached
    }

    const fetchPromise = this.fetchPlannedMeetings(guestId).then(
      (profile) => {
        const ttlSeconds = profile ? 60 : 5 // 1mn or 5s
        this.plannedMeetingsCache.set(guestId, profile, { ttlSeconds })
        return profile
      },
      (err) => {
        this.plannedMeetingsCache.invalidate(guestId)
        return Promise.reject(err)
      }
    )
    this.plannedMeetingsCache.set(guestId, fetchPromise, { ttlSeconds: 60 }) // 1mn
    return fetchPromise
  }

  async countPlannedMeetings(guestId: string): Promise<number> {
    const plannedMeetings = await this.getPlannedMeetings(guestId)
    return plannedMeetings ? plannedMeetings.length : 0
  }

  private async fetchPlannedMeetings(guestId: string): Promise<Array<PlannedMeeting> | null> {
    const { status, data } = await axios.get<{ plannedMeetings?: Array<PlannedMeeting> }>(
      `/backoffice/events/${this.eventId}/matchmaking/meetings/${guestId}/planned`,
      {
        validateStatus: (status) => [200, 404].includes(status)
      }
    )
    if (status === 404) {
      return null
    }
    const plannedMeetings = data.plannedMeetings
    if (!plannedMeetings) {
      throw new Error('Profile not found in response')
    }
    return plannedMeetings
  }

  private async fetchUnscheduledMeetings(guestId: string): Promise<Array<UnscheduledMeeting> | null> {
    const { status, data } = await axios.get<{ unscheduledMeetings?: Array<UnscheduledMeeting> }>(
      `/backoffice/${this.eventId}/matchmaking/meetings/${guestId}/unscheduled`,
      {
        validateStatus: (status) => [200, 404].includes(status)
      }
    )
    if (status === 404) {
      return null
    }
    const unscheduledMeetings = data.unscheduledMeetings
    if (!unscheduledMeetings) {
      throw new Error('Profile not found in response')
    }
    return unscheduledMeetings
  }

  async getUnscheduledMeetings(guestId: string): Promise<Array<UnscheduledMeeting> | null> {
    if (!guestId) {
      throw new Error('guestId is required')
    }
    const cached = this.unscheduledMeetingsCache.get<UnscheduledMeetingsOrPromise>(guestId)
    if (cached) {
      // May be profile or promise to profile
      return cached
    }

    const fetchPromise = this.fetchUnscheduledMeetings(guestId).then(
      (profile) => {
        const ttlSeconds = profile ? 60 : 5 // 1mn or 5s
        this.unscheduledMeetingsCache.set(guestId, profile, { ttlSeconds })
        return profile
      },
      (err) => {
        this.unscheduledMeetingsCache.invalidate(guestId)
        return Promise.reject(err)
      }
    )
    this.unscheduledMeetingsCache.set(guestId, fetchPromise, { ttlSeconds: 60 }) // 1mn
    return fetchPromise
  }

  async countUnscheduledMeetings(guestId: string): Promise<number> {
    const unscheduledMeetings = await this.getUnscheduledMeetings(guestId)
    return unscheduledMeetings ? unscheduledMeetings.length : 0
  }

  private static _instance: GuestPlanningMeetingFetcher | null = null
  public static get instance(): GuestPlanningMeetingFetcher {
    if (!GuestPlanningMeetingFetcher._instance) {
      throw new Error('GuestPlanningMeetingFetcher is not initialized')
    }
    return GuestPlanningMeetingFetcher._instance
  }

  public static initialize({ eventId }: { eventId: string }) {
    GuestPlanningMeetingFetcher._instance = new GuestPlanningMeetingFetcher(eventId)
  }
}
