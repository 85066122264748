import { getAccount, saveAccount } from '@/services/account'
import type { PasswordLoginCredentials, SignUpCredentials } from '@/services/authentication'
import { login, logout, signUp } from '@/services/authentication'
import type { Account } from '@/models/Account'
import type { Module } from 'vuex'
import type { StoreState } from '..'

export interface AccountState {
  account: Account | null
}

const storeModule: Module<AccountState, StoreState> = {
  state: {
    account: null
  },
  getters: {
    userLang: (state) => {
      if (state.account && state.account.language) return state.account.language
      else return navigator.language
    }
  },
  actions: {
    login(context, credentials: PasswordLoginCredentials) {
      return login(credentials)
    },
    signup(context, credentials: SignUpCredentials) {
      return signUp(credentials)
    },
    async logout(context): Promise<void> {
      const res = await logout()
      context.commit('CLEAR_ACCOUNT')
      return res
    },
    async loadAccount(context) {
      const account = await getAccount()
      context.commit('SET_ACCOUNT', account)
      return account
    },
    /**
     * @description
     * Attempts to fetch account info from wherever possible
     * Will request it from API if necessary
     * @summary Attempts to fetch the account info
     */
    async ensureAccountLoaded(context): Promise<Account> {
      if (!context.state.account) {
        return context.dispatch('loadAccount')
      } else {
        return context.state.account
      }
    },
    async saveAccount(context, account) {
      const res = await saveAccount(account)
      context.commit('SET_ACCOUNT', res)
      return res
    }
  },
  mutations: {
    SET_ACCOUNT(state, account) {
      state.account = account
    },
    CLEAR_ACCOUNT(state) {
      state.account = null
    }
  }
}

export default storeModule
