import type { ReadonlyFullRouteConfig } from '@/core'

export const matchmakingRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'programme/matchmaking',
    name: 'event.programme.matchmaking',
    component: () => import('./MatchmakingTabs.vue'),
    children: [
      {
        path: 'programme/matchmaking/settings',
        name: 'event.programme.matchmaking.settings',
        component: () => import('./settings/MatchmakingSettings.vue')
      }
    ]
  },
  {
    path: 'programme/matchmaking/session/:activityId',
    name: 'event.programme.matchmaking.session.edition',
    component: () => import('./sessions/MatchmakingSessionView.vue'),
    meta: {
      renderingData: {
        shouldDisplayPageHeader: false
      }
    }
  },
  {
    path: 'programme/matchmaking/zone/:zoneId',
    name: 'event.programme.matchmaking.zone.edition',
    component: () => import('./locations/areas/MatchmakingAreaView.vue'),
    meta: {
      renderingData: {
        shouldDisplayPageHeader: false
      }
    }
  },
  {
    path: 'programme/rules/edition/:ruleId?',
    name: 'programme.rules.edition',
    component: () => import('./rules/ProgrammeRulesEditionView.vue'),
    meta: {
      renderingData: {
        shouldDisplayPageHeader: false
      }
    }
  }
]
