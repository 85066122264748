export class InMemoryObjectCache {
  private cache: Record<string, { v: unknown | undefined; validUntilTimestampMs: number }> = {}

  get<T extends object | null>(key: string) {
    const value = this.cache[key]
    if (typeof value === 'undefined') return undefined
    if (value.validUntilTimestampMs < Date.now()) {
      delete this.cache[key]
      return undefined
    }
    return value.v as T
  }

  set<T extends object | null>(key: string, value: T | null, { ttlSeconds }: { ttlSeconds: number }) {
    if (ttlSeconds === 0) return
    this.cache[key] = {
      v: value,
      validUntilTimestampMs: Date.now() + ttlSeconds * 1000
    }
  }

  invalidate(key: string) {
    delete this.cache[key]
  }
}
