import type { MongoDb } from '@/models/MongoDbTools'
import { ErrorWithExtras } from '@/utils/errorTypes'
import { Type } from 'class-transformer'
import type { CustomFieldId, GuestFieldKey } from '../../GuestField'
import { TagGroup } from './Tag'
import type { EventAppBranding } from './EventAppBranding'
import type { EventAppHome } from '@/models/Event/modules/Programme/EventAppHome'
import type { SponsorId } from './Sponsor'
import Sponsor from './Sponsor'
import type { MixedSort } from '@/models/commons/sort'

export type EventActivityId = MongoDb.ObjectId

export type DateRange = [Date, Date]

type ActivityBase = {
  _id: string
  title: string
}

export type GroupActivity = ActivityBase & {
  dateRange: DateRange
  eligibleSegmentIds: string[]
  hasVisio: boolean
  isQuotaReached: boolean
  isVisioEmbededInDigitevent: boolean
  kind: 'groupActivity'
  liveAccessOffset: number
  mandatoryIfEligible: boolean
  quota?: number
  replayUrl?: string
  speakers: MongoDb.ObjectId[]
  sponsors: SponsorId[]
  tagIds: MongoDb.ObjectId[]
  visioLink?: string
  location: string
  description: string
}

export interface MatchmakingRule {
  _id: string
  applicantSegmentId: string
  targetSegmentIds: string[]
}

export interface MatchmakingQuota {
  _id: string
  segmentId: string
  maxScheduledMeeting: number
  maxExcessPendingMeeting: number
}

export function getEmptyMatchmakingQuota(): MatchmakingQuota {
  return {
    _id: '',
    segmentId: '',
    maxScheduledMeeting: 0,
    maxExcessPendingMeeting: 0
  }
}

export interface TrombinoSearchField {
  key: CustomFieldId
}

export type MatchmakingSession = ActivityBase & {
  kind: 'matchmakingSession'
  rules: MatchmakingRule[]
  dateRanges: DateRange[]
  hasVisio: boolean
  meetingDuration: number
  includedBreakDuration: number
  areaIds: string[]
}

export type Activity = GroupActivity | MatchmakingSession

export type ActivityKind = Activity['kind']

export interface MatchmakingMeetingPoint {
  _id: string
  name: string
}

export interface MatchmakingMeetingArea {
  _id: string
  name: string
  points: MatchmakingMeetingPoint[]
}

export type MatchmakingOptions = {
  meetingRequestRequiredMotivation: boolean
  showContactInformations: boolean
  lockScheduledMeeting: boolean
  quotas: MatchmakingQuota[]
  cardHighlightOn: 'person' | 'organization'
  trombinoSearchFields: TrombinoSearchField[]
  meetingScheduler: 'applicant' | 'target'
  introductionHtml: string
  canGuestSetUnavailabilities: boolean
  eventAppTabName: string
  canReportNoShows: boolean
}

export class Programme {
  eventActivities: Activity[] = []

  getActivityById(targetId: EventActivityId): Activity {
    const result = this.eventActivities.find((activity) => activity._id === targetId)

    if (result) {
      return result
    } else {
      throw new ErrorWithExtras(`No activity matches ID ${targetId}.`, { activityId: targetId })
    }
  }

  *groupActivities(): Generator<GroupActivity> {
    for (const activity of this.eventActivities) {
      if (activity.kind === 'groupActivity') yield activity
    }
  }

  *matchMakingSessions(): Generator<MatchmakingSession> {
    for (const activity of this.eventActivities) {
      if (activity.kind === 'matchmakingSession') yield activity
    }
  }

  getMatchmakingSessionById(targetId: EventActivityId): MatchmakingSession {
    const result = this.getActivityById(targetId)
    if (result.kind === 'matchmakingSession') {
      return result
    } else {
      throw new ErrorWithExtras('Invalid match for matchmaking session: Activity is not a matchmaking session.', {
        activityId: targetId
      })
    }
  }

  getGroupActivityById(targetId: EventActivityId): GroupActivity {
    const result = this.getActivityById(targetId)
    if (result.kind === 'groupActivity') {
      return result
    } else {
      throw new ErrorWithExtras('Invalid match for group activity: Activity is not a group activity.', {
        activityId: targetId
      })
    }
  }

  @Type(() => Sponsor)
  sponsors!: Sponsor[]

  sponsorsSort!: MixedSort
  shuffleSponsors!: boolean
  showSponsorsPage!: boolean

  @Type(() => GroupActivityTag)
  availableTags!: GroupActivityTag[]

  @Type(() => TagGroup)
  tagGroups!: TagGroup[]

  profileFields!: GuestFieldKey[]

  eventAppBranding!: EventAppBranding
  eventAppHome!: EventAppHome
  matchmakingOptions!: MatchmakingOptions
  matchmakingAreas!: MatchmakingMeetingArea[]
}

export class GroupActivityTag {
  _id!: MongoDb.ObjectId
  color: string = '#333333'
  tagName!: string
}
