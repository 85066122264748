import * as Sentry from '@sentry/browser'
import { isDev } from './isDev'

if (!isDev) {
  const release = process.env.VUE_APP_SENTRY_RELEASE_NAME
  const environment = process.env.NODE_ENV
  Sentry.init({
    dsn: 'https://8f379d20627d40fa888896cd986a3cf0@sentry.io/5187505',
    integrations: [],
    release,
    environment,
    ignoreErrors: [
      /Request failed with status code 4\d[013]/gim, // 4xx errors = client errors // but we allow 404
      /Cannot read properties of null (reading 'destroyed')/gim, // TinyMCE error
      /Request failed with status code 401/gim,
      /Unexpected token '<'/gim,
      /Loading chunk \d+ failed/gim,
      /Loading CSS chunk \d+ failed/gim,
      /ResizeObserver loop limit exceeded/gim, // can be ignored: https://stackoverflow.com/a/50387233
      /unexpected token '<' \(at icons\.js:1:1\)/gim, // tinymce error => it try to load default icons file from /js folder but it's not there see https://www.tiny.cloud/docs/tinymce/6/creating-an-icon-pack/#deploy-the-icon-pack-and-tinymce-separately
      /Page with id [a-zA-Z0-9]+ not found/gim
    ],
    beforeSend(event, hint) {
      const isAxiosError =
        event.exception?.values?.length === 1 &&
        event.exception?.values[0]?.type === 'AxiosError' &&
        event.exception?.values[0]?.value?.toLowerCase().includes('Network Error')
      if (isAxiosError) {
        return null
      }
      if (event.request?.url?.startsWith('chrome-extension://' || 'moz-extension://')) {
        return null
      }
      if (
        event.stacktrace?.frames?.some(
          (f) => f.abs_path?.includes('chrome-extension') || f.abs_path?.includes('moz-extension')
        )
      ) {
        return null
      }
      const error = hint?.originalException
      if (error && typeof error === 'string' && error.includes('ResizeObserver')) {
        return null
      }
      if (error && typeof error === 'object' && (error as { message?: string }).message?.includes('ResizeObserver')) {
        return null
      }
      return event
    }
  })
  console.info(`Sentry initialized with release '${release}' and environment '${environment}'`)
  if (!release) {
    noSentryReleaseName()
  }
}

function noSentryReleaseName() {
  const err = new Error(`No sentry release name specified`)
  console.error(err)
  Sentry.captureException(err)
}
