









import { Component, Vue, Prop } from 'vue-property-decorator'
import type { CancelledMeeting } from '@/digiteventApi'

@Component
export default class MeetingCancelBadge extends Vue {
  @Prop({ required: true }) readonly meeting!: CancelledMeeting
}
