
















import { ContactProfileModal } from '@/features/audience/contactProfile'
import type { MatchmakingSession } from '@/models/Event/modules/Programme'
import { joinIfExist } from '@/utils/string'
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import type { GuestAppProfile } from '../../../../../digiteventApi/eventApp/appProfile'

@Component({ components: { ContactProfileModal } })
export default class CounterPartProfile extends Vue {
  @Prop({ required: true }) readonly profile!: GuestAppProfile
  @Prop({ required: true }) readonly matchmakingSession!: MatchmakingSession

  @Ref() readonly contactProfileModal!: ContactProfileModal

  get fullName(): string {
    return joinIfExist([this.profile.firstname, this.profile.name])
  }

  get jobAndOrganization(): string {
    return joinIfExist([this.profile.job, this.profile.organization], ', ')
  }

  openCounterPartProfileModal() {
    this.contactProfileModal.loadGuestFromIdAndOpenModal(this.profile._id)
  }
}
