











import { Vue, Component, Prop } from 'vue-property-decorator'
import { isValidUrl } from '@/utils/validation'
import { timezoneFormat } from '@/lib/timezoneFormat'
import type { HistoryModification } from './types'

@Component({})
export default class ModificationItem extends Vue {
  @Prop({ required: true }) readonly modification!: HistoryModification

  get formattedValue() {
    switch (this.modification.fieldType) {
      case 'list':
        return this.modification.value.join(', ')
      case 'dateWithoutTimezone':
        return timezoneFormat(this.modification.value)
      default:
        return this.modification.value
    }
  }

  get isUrl(): boolean {
    if (typeof this.modification.value !== 'string') {
      return false
    }

    return isValidUrl(this.modification.value)
  }
}
