import { render, staticRenderFns } from "./DigiCardItemInformation.vue?vue&type=template&id=75871767&scoped=true"
import script from "./DigiCardItemInformation.vue?vue&type=script&lang=ts"
export * from "./DigiCardItemInformation.vue?vue&type=script&lang=ts"
import style0 from "./DigiCardItemInformation.vue?vue&type=style&index=0&id=75871767&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75871767",
  null
  
)

export default component.exports