

































import { Component, Prop, Ref } from 'vue-property-decorator'
import type Guest from '@/models/Guest'
import type { MomentInput } from 'moment'
import moment from 'moment'
import MeetingRequestAcceptationModal from './MeetingRequestAcceptationModal.vue'
import type { ScheduledItemId } from '@/models/Guest/Planning/ScheduledItem'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

import { ContactProfileModal } from '@/features/audience/contactProfile'
import type { UnscheduledMeeting } from '@/digiteventApi'
import { removeMeetingRequestFrom } from '../../../../digiteventApi'
import MeetingRequestReceivedItem from './MeetingRequestReceivedItem.vue'
import MeetingRequestSentItem from './MeetingRequestSentItem.vue'

@Component({
  components: {
    MeetingRequestSentItem,
    MeetingRequestReceivedItem,
    MeetingRequestAcceptationModal,
    ContactProfileModal
  },
  filters: {
    timeAgo(isoDate: MomentInput) {
      return moment(isoDate).fromNow()
    }
  }
})
export default class MeetingRequests extends Vue {
  @Prop() readonly guest!: Guest
  @Prop() readonly unscheduledMeetings!: UnscheduledMeeting[]
  @Ref() readonly contactProfileModal!: ContactProfileModal

  requestBeingCancelled: ScheduledItemId | null = null

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get receivedUnscheduledMeetings() {
    return this.unscheduledMeetings.filter((meeting) => meeting.applicantId !== this.guest._id)
  }

  get sentUnscheduledMeeting() {
    return this.unscheduledMeetings.filter((meeting) => meeting.applicantId === this.guest._id)
  }

  openContactProfileModal(guestId: string) {
    this.contactProfileModal.loadGuestFromIdAndOpenModal(guestId)
  }

  async cancelMeetingRequest(targetRequest: UnscheduledMeeting) {
    if (targetRequest.status !== 'pendingAcceptation') {
      throw new Error('Cannot cancel a meeting request that is not pending acceptation')
    }
    this.requestBeingCancelled = targetRequest._id

    try {
      await removeMeetingRequestFrom({
        eventId: this.storeEvent._id,
        meetingRequestId: targetRequest._id
      })
      this.$emit('reload', targetRequest)
    } finally {
      this.requestBeingCancelled = null
    }
  }
}
