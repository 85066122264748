import type { EventId } from '@/models/Event'
import type { Event } from '@/models/Event'
import type { FilterId } from '@/models/Event/Filter'
import type { GuestFieldKey } from '@/models/Event/GuestField'
import type { GuestField } from '@/models/Event/GuestField'
import { i18n } from '@/utils/i18n'
import { sortMixed } from '@/utils/sort'
import axios from 'axios'

export function getGuestField(guestFields: GuestField[], guestFieldKey: GuestFieldKey): GuestField {
  const guestField = guestFields.find((f) => f.key === guestFieldKey)
  if (!guestField) {
    throw new Error('No guest field for this key ' + guestFieldKey)
  }
  return guestField
}

export function getGuestFieldsFromKeys(guestFields: GuestField[], guestFieldKeys: GuestFieldKey[]): GuestField[] {
  return guestFieldKeys.map((key) => {
    const guestField = guestFields.find((f) => f.key === key)
    if (!guestField) {
      throw new Error('No guest field for this key ' + key)
    }
    return guestField
  })
}

export function getGuestFieldsWithCustomFieldOrder(event: Event): GuestField[] {
  // The result starts with fields that are builtin (server-issued, we didn't make those)
  const result = event.guestFields.filter((f) => f.origin === 'builtin')
  // Then we work with the rest
  const restOfFields = event.guestFields.filter((f) => f.origin !== 'builtin')
  const sortedCustomGuestFields = sortMixed({
    arrayToSort: restOfFields,
    sortKey: 'key',
    sortOrder: event.customFieldsSortOrder
  })
  result.push(...sortedCustomGuestFields)
  return result
}

export type FieldStatValue = string | number | boolean | null

export type FieldStatItem = {
  _id: FieldStatValue
  count: number
}

export async function fetchFieldStats(
  eventId: EventId,
  fieldKey: GuestFieldKey,
  options: { filterId?: FilterId } = {}
): Promise<FieldStatItem[]> {
  const response = await axios.get<FieldStatItem[]>(`backoffice/events/${eventId}/fields/${fieldKey}/stats`, {
    params: { filterId: options.filterId }
  })

  return response.data
}

export function getTranslatedFieldName(name: string) {
  if (['NAME', 'FIRSTNAME', 'GUESTEMAIL', 'MOBILEPHONE', 'PHOTO', 'JOB', 'ORGANIZATION'].includes(name)) {
    return i18n.t(name)
  }
  return name
}
