
























import { DigiFormLayout } from '@/components/ui/form/formLayouts'
import type { DigiFormGroupItem } from '@/components/ui/form/formGroup'
import { DigiFormGroup } from '@/components/ui/form/formGroup'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { type MatchmakingSession } from '@/models/Event/modules/Programme'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'

import { DigiButton } from '@/components/ui/actions'
import { DigiFormModal2 } from '@/components/ui/form/formLayouts'
import { createMatchmakingMeetingPoint } from '@/digiteventApi'
import MeetingPointGuestSelector from './MeetingPointGuestSelector.vue'
import type { AssignableGuest } from '@/digiteventApi'

@Component({
  components: {
    MeetingPointGuestSelector,
    DigiFormModal2,
    DigiButton,
    DigiFormLayout,
    DigiFormGroup
  }
})
export default class  MeetingPointCreationModal extends Vue {
  @Prop ({ required: true }) readonly zoneId!: string
 isOpen = false

  isLoading = false
  activity: MatchmakingSession | undefined

  name = ''
  assignedGuest: AssignableGuest | null = null

  show() {
    this.name = ''
    this.assignedGuest = null
    this.isOpen = true
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get formItems(): DigiFormGroupItem[] {
    return [
      { key: 'name', label: this.$t('MATCHMAKING_CREATE_MEETING_POINT_NAME_LABEL'), validationRules: { required: true } },
      { key: 'assignee', label: this.$t('MATCHMAKING_CREATE_MEETING_POINT_TYPE_LABEL'), displayMode: 'rows'},
    ]
  }

  get hasModifications(): boolean {
    return this.name.length > 0
  }


  onHideModal() {
    this.isOpen = false
    this.$emit('cancel')
  }

  async createZone(): Promise<void> {
    this.isLoading = true
    try {
      await createMatchmakingMeetingPoint({
        eventId: this.storeEvent._id,
        zoneId: this.zoneId,
        name: this.name,
        assignedGuestId: this.assignedGuest?._id ?? null
      })
      await this.$store.dispatch('reloadCurrentEvent')
      this.isOpen = false
    } finally {
      this.isLoading = false
    }
  }
}
