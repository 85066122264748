

















import { assertEvent } from '@/services/storeEvent'
import type { Localized } from '@/utils/i18n'
import { Component, Vue, VModel, Ref, Prop } from 'vue-property-decorator'
import type { Event } from '@/models/Event'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import { DigiIconButton } from '@/components/ui/actions'
import { DigiFormModal2 } from '@/components/ui/form/formLayouts'
import LocalizedInputModal from './LocalizedInputModal.vue'
import { WysiwygEditor } from '@/components/features/wysiwyg'

@Component({
  components: {
    DigiIconButton,
    DigiFormModal2,
    LocalizedInputModal,
    WysiwygEditor
  }
})
export default class LocalizedInput extends Vue {
  @VModel({ required: true }) model!: Localized<string>
  @Prop({ default: '' }) readonly placeholder!: string
  @Prop({ default: 'md' }) readonly size!: 'sm' | 'md'
  @Prop({ default: false }) readonly required!: boolean
  @Prop({ default: 'text' }) readonly type!: 'text' | 'wysiwyg'

  @Ref() readonly modal!: LocalizedInputModal

  get inputValue(): string {
    return this.model[this.storeEvent.mainLanguageCode] ?? ''
  }
  set inputValue(value: string) {
    this.model = {
      ...this.model,
      [this.storeEvent.mainLanguageCode]: value
    }
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  openModal(): void {
    this.modal.open({ ...this.model })
  }

  setTranslations(values: Localized<string>): void {
    this.model = {
      ...values
    }
  }
}
