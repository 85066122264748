














import type { EventLangCode } from '@/models/Event/Localized'
import type { Localized } from '@/utils/i18n'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getAllEditableLanguages } from '../helper'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import { TranslationsInputs } from '@/components/features/translations'
import { isNotEqual } from '@/utils/object'
import { DigiFormModal2 } from '@/components/ui/form/formLayouts'

@Component({
  components: {
    DigiFormModal2,
    TranslationsInputs
  }
})
export default class LocalizedInputModal extends Vue {
  @Prop({ default: 'text' }) readonly type!: 'text' | 'wysiwyg'

  isOpen = false
  localizedValues: Localized<string> = {}
  initialLocalizedValues: Localized<string> = {}

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get allEditableLanguages(): EventLangCode[] {
    return getAllEditableLanguages({
      mainLanguage: this.storeEvent.mainLanguageCode,
      translations: this.storeEvent.modules_data.website.websiteTranslations
    })
  }

  get hasModifications(): boolean {
    return isNotEqual(this.localizedValues, this.initialLocalizedValues)
  }

  open(localizedValues: Localized<string>): void {
    this.initialLocalizedValues = { ...localizedValues }
    this.localizedValues = { ...localizedValues }
    this.isOpen = true
  }

  close(): void {
    this.isOpen = false
  }

  submit(): void {
    this.$emit('update', this.localizedValues)
    this.close()
  }

  reset(): void {
    this.localizedValues = { ...this.initialLocalizedValues }
  }
}
