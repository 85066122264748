








































import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DigiButton } from '@/components/ui/actions'
import { assertEvent } from '@/services/storeEvent'
import type { Event as EventModel } from '@/models/Event'
import type { MeetingPossibleSlot, PendingAcceptationMeeting } from '@/digiteventApi'
import { getAvailableTimeSlotsForMeeting } from '@/digiteventApi'
import { scheduleMeetingRequestFromBo } from '@/digiteventApi'

interface TimeSlotOption {
  text: string
  value?: MeetingPossibleSlot
  disabled?: boolean
}

@Component({
  components: { DigiButton }
})
export default class MeetingRequestAcceptationModal extends Vue {
  @Prop({ required: true }) private guestId!: string
  private isLoadingSlots: boolean = false
  private slots: MeetingPossibleSlot[] | null = null
  private pickedTimeSlot: MeetingPossibleSlot | null = null
  private showModal: boolean = false
  private request: PendingAcceptationMeeting | null = null
  private isAccepting: boolean = false

  get storeEvent(): EventModel {
    return assertEvent(this.$store.state.event.event)
  }

  get timeSlotOptions(): TimeSlotOption[] {
    if (this.slots) {
      if (this.slots.length > 0) {
        const options: TimeSlotOption[] = [
          {
            disabled: true,
            text: this.$t('PICK_A_TIME_SLOT')
          }
        ]

        return options.concat(
          this.slots.map((slot) => ({
            text: moment(slot.dateRange.start).format('l LT'),
            value: slot
          }))
        )
      } else {
        return [
          {
            text: this.$t('NO_MEETING_SLOT_AVAILABLE'),
            disabled: true
          }
        ]
      }
    } else {
      return []
    }
  }

  get canSelectATimeSlot(): boolean {
    return !this.isLoadingSlots && !this.isAccepting && Array.isArray(this.slots) && this.slots.length > 0
  }

  async loadSlots(): Promise<void> {
    if (this.isLoadingSlots) throw new Error('Already loading slots.')
    if (!this.request) throw new Error('Cannot load slots: No request to load slots for.')
    this.slots = null
    this.pickedTimeSlot = null
    this.isLoadingSlots = true
    try {
      this.slots = await getAvailableTimeSlotsForMeeting({
        eventId: this.storeEvent._id,
        applicantId: this.request.applicantId,
        targetId: this.guestId
      })
    } finally {
      this.isLoadingSlots = false
    }
  }

  acceptRequest(request: PendingAcceptationMeeting): void {
    this.request = request
    this.pickedTimeSlot = null
    this.showModal = true
  }

  async finishAcceptingRequest(): Promise<void> {
    if (!this.request) throw new Error('Cannot accept request: no request set.')
    if (!this.pickedTimeSlot) throw new Error('Cannot accept request: no time slot picked.')
    if (this.isAccepting) throw new Error('Already accepting request.')

    this.isAccepting = true
    try {
      await scheduleMeetingRequestFromBo({
        eventId: this.storeEvent._id,
        meetingRequestId: this.request._id,
        slotId: this.pickedTimeSlot.id,
        sessionId: this.pickedTimeSlot.sessionId
      })
      this.showModal = false
    } finally {
      this.isAccepting = false
    }

    this.$emit('accepted')
  }

  handleModalHide(event: Event): void {
    if (this.isAccepting) event.preventDefault()
  }

  handleModalHidden(): void {
    this.pickedTimeSlot = null
    this.slots = null
    this.request = null
  }
}
