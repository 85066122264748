import type { ReadonlyFullRouteConfig } from '@/core'

export const programmeSettingsRoutes: ReadonlyFullRouteConfig = [
  {
    path: 'programme/settings',
    name: 'programme.settings',
    component: () => import('@/features/eventApp/settings/general/ProgrammeGeneralSettingsView.vue'),
    meta: {
      name: 'FACTORY_PROGRAMME_SETTINGS',
      iconName: 'printer-line',
      keywords: 'FACTORY_PROGRAMME_SETTINGS_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.eventApp.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  },
  {
    path: 'programme/branding',
    name: 'programme.branding',
    component: () => import('./branding/EventAppPersonalizationView.vue'),
    meta: {
      name: 'FACTORY_PROGRAMME_BRANDING',
      iconName: 'printer-line',
      keywords: 'FACTORY_PROGRAMME_BRANDING_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.eventApp.allowed,
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  },
  {
    path: 'programme/chat',
    name: 'programme.chat',
    component: () => import('@/features/eventApp/settings/chat/EventAppChatSettingsView.vue'),
    meta: {
      name: 'FACTORY_CHAT_SETTINGS',
      iconName: 'printer-line',
      keywords: 'FACTORY_CHAT_SETTINGS_KEYWORDS',
      searchable: true,
      requiredPermissions: {
        permissions: ['configEvent'],
        redirectName: 'event.dashboard'
      },
      requiredRights: {
        hasRequiredRights: (eventRights) => eventRights.features.eventApp.chat,
        redirectName: 'event.settings.inactiveFeatures'
      }
    }
  }
] as const
