






























import { Vue, Component, Prop } from 'vue-property-decorator'
import type { Modification, ModificationContext, ModificationEntry } from '@/services/guestHistory'
import { timezoneFormat } from '@/lib/timezoneFormat'
import { DigiButton } from '@/components/ui/actions'
import ModificationItem from './ModificationItem.vue'
import type { HistoryModification } from './types'

@Component({
  components: { ModificationItem, DigiButton },
  filters: {
    timezoneFormat
  }
})
export default class ModificationCard extends Vue {
  @Prop({ required: true }) readonly entry!: ModificationEntry

  showMore: boolean = false

  get modificationContextText(): string {
    const texts: Record<ModificationContext, string> = {
      back: this.$t('GUEST_HISTORY_MODIFICATION_CONTEXT_BACK'),
      api: this.$t('GUEST_HISTORY_MODIFICATION_CONTEXT_API'),
      server: this.$t('GUEST_HISTORY_MODIFICATION_CONTEXT_SERVER'),
      checkin: this.$t('GUEST_HISTORY_MODIFICATION_CONTEXT_CHECKIN'),
      attendee: this.$t('GUEST_HISTORY_MODIFICATION_CONTEXT_ATTENDEE'),
      mainGuest: this.$t('GUEST_HISTORY_MODIFICATION_CONTEXT_MAINGUEST')
    }
    return texts[this.entry.context] ?? texts.server
  }

  get displayedModifications(): HistoryModification[] {
    if (!this.showMore) {
      const firstKeys = Object.keys(this.entry.modifications).slice(0, 5)
      const result: HistoryModification[] = []
      for (const key of firstKeys) {
        const modification = this.entry.modifications[key] as Modification
        result.push({ key, ...modification })
      }
      return result
    }

    return Object.keys(this.entry.modifications).map((key) => {
      const modification = this.entry.modifications[key] as Modification
      return { key, ...modification }
    })
  }

  get numberOfModifications(): number {
    return Object.keys(this.entry.modifications).length
  }

  get hasShowMoreButton(): boolean {
    return this.numberOfModifications > 5
  }
}
