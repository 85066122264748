








import { Vue, Component, Prop } from 'vue-property-decorator'
import type { AssignableGuest } from '@/digiteventApi'
import { DigiCardItemInformation } from '@/components/ui/cardItem'
import { joinIfExist } from '@/utils/string'
import { DigiSimpleIconButton } from '@/components/ui/actions'

@Component({
  components: { DigiSimpleIconButton, DigiCardItemInformation }
})
export default class MeetingPointAssignedGuestItem extends Vue {
  @Prop({ required: true }) readonly guest!: AssignableGuest

  get fullName(): string {
    return joinIfExist([this.guest.firstname, this.guest.name], ' ')
  }
}
