



















































import { DigiFormGroup } from '@/components/ui/form/formGroup'
import CustomFieldSection from './components/CustomFieldSection.vue'

import type Guest from '@/models/Guest'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import type { GuestField, GuestFieldKey } from '@/models/Event/GuestField'
import { DigiButton } from '@/components/ui/actions'
import { isSortGroup } from '@/utils/sort'
import type { MixedSort, SortGroup } from '@/models/commons/sort'
import SortableMixed from '@/components/features/sortable/sortableMixed/SortableMixed.vue'
import { guestFieldToFieldItem } from '@/features/audience/contactEdit/service'
import EditFieldItem from '@/features/audience/contactEdit/components/EditFieldItem.vue'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'

@Component({
  components: {
    EditFieldItem,
    SortableMixed,
    DigiButton,
    CustomFieldSection,
    DigiFormGroup
  }
})
export default class ContactForm extends Vue {
  @Prop({ required: true }) readonly guest!: Partial<Guest>
  @Prop({ required: true }) readonly loading!: boolean
  @Prop({ required: true }) readonly isDuplicateEmail!: boolean

  localIsDuplicateEmail = false
  localGuest: Partial<Guest> = {}
  validationMap: Record<GuestFieldKey, boolean> = {}

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get fieldMixedSort() {
    const builtinFields = this.builtinFields.map((field: GuestField) => field.key)
    return [...builtinFields, ...this.storeEvent.customFieldsSortOrder]
  }

  get formId(): string {
    return `form-${this.$uuid}`
  }

  isSortGroup(item: MixedSort[number]): item is SortGroup {
    return isSortGroup(item)
  }

  get guestFieldsItemsMap() {
    return guestFieldToFieldItem(this.storeEvent.guestFields)
  }

  get builtinFields(): GuestField[] {
    return this.storeEvent.guestFields.filter((field: GuestField) => field.origin === 'builtin')
  }

  get allFieldsAreValid(): boolean {
    return (
      !this.localIsDuplicateEmail && Object.values(this.validationMap).every((validity: boolean) => validity === true)
    )
  }

  @Watch('guest', { immediate: true })
  onGuestPropChanged(): void {
    this.cloneGuest()
  }

  @Watch('localGuest.guestemail')
  onGuestEmailChanged(newVal?: string | null, oldVal?: string | null): void {
    if (oldVal && newVal && newVal !== oldVal) {
      this.localIsDuplicateEmail = false
    }
  }

  @Watch('isDuplicateEmail')
  onDuplicateEmailChanged(newVal: boolean): void {
    this.localIsDuplicateEmail = newVal
  }

  cloneGuest(): void {
    this.localGuest = cloneDeep(this.guest)
  }

  updateFieldValidities(fieldKey: GuestFieldKey, validity: boolean): void {
    this.$set(this.validationMap, fieldKey, validity)
  }

  submit() {
    if (this.allFieldsAreValid) {
      this.$emit('submit', this.localGuest)
    }
  }
}
