













import Editor from '@tinymce/tinymce-vue'
import { Vue, Component, Prop } from 'vue-property-decorator'
import type { EventLangCode, LocalizedPrimitive } from '@/models/Event/Localized'
import TranslationsInputItem from './TranslationsInputItem.vue'

@Component({
  components: {
    TranslationsInputItem,
    Editor
  }
})
export default class TranslationsInputs extends Vue {
  @Prop({ required: true }) readonly value!: LocalizedPrimitive<unknown>
  @Prop({ required: true }) readonly languages!: EventLangCode[]
  @Prop() readonly isEditor!: boolean
}
