import store from '@/store'
import type { TriggerUtil } from '../../RuleEdition/types'
import { TriggerKind } from '@/models/Event/RulesData/Rule/Trigger'
import { i18n } from '@/utils/i18n'

const canceledMeetingTriggerUtil: TriggerUtil = {
  kind: TriggerKind.CanceledMeeting as const,
  label: 'CANCELED_MEETING' as const,
  icon: 'user-unfollow-line' as const,
  nominativeTags: [
    { title: () => i18n.t('TAG_NAME_MEETING_COUNTERPART_FULLNAME'), value: '*|MEETING_COUNTERPART_FULLNAME|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_COUNTERPART_ORGANIZATION'), value: '*|MEETING_COUNTERPART_ORGANIZATION|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_COUNTERPART_JOB'), value: '*|MEETING_COUNTERPART_JOB|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_DATETIME'), value: '*|MEETING_DATETIME|*' },
    { title: () => i18n.t('TAG_NAME_MEETING_CANCELLATION_REASON'), value: '*|MEETING_CANCELLATION_REASON|*' }
  ] as const,

  isAllowed(): boolean {
    return store.state.event?.rights.features.eventApp.matchmaking
  }
}

export default canceledMeetingTriggerUtil
