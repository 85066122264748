































import { Component, Watch, Prop } from 'vue-property-decorator'
import type { GuestId } from '@/models/Guest'
import type Guest from '@/models/Guest'
import { getGuestById } from '@/services/guest'
import ContactProfileHeader from './components/ContactProfileHeader.vue'
import type { EventRights } from '@/models/Event/EventRights'
import { assertEventRights } from '@/services/eventRights'
import ContactProfileMenu from './components/ContactProfileMenu.vue'
import type { GuestHistory } from '@/services/guestHistory'
import { getGuestHistory } from '@/services/guestHistory'
import type { Permission } from '@/services/eventPermissions'
import { listMyPermissions } from '@/services/eventPermissions'
import type { ContactProfileQueryParams, ContactProfileTabKey } from './types'
import { contactProfileQueryParamKeys } from './types'
import { isContactProfileTabKey } from './types'
import { convertQueryStringToParamsObject } from '@/lib/url'
import { assertEvent } from '@/services/storeEvent'
import type { Event } from '@/models/Event'
import Vue from 'vue'
import { GuestPlanningMeetingFetcher } from '../../eventApp'

@Component({
  components: {
    ContactProfileMenu,
    ContactProfileHeader
  }
})
export default class ContactProfileModal extends Vue {
  @Prop({ type: Boolean, default: false }) readonly autoOpen!: boolean

  isOpen = false
  loading: boolean = false
  loadedGuest: Guest | null = null
  history: GuestHistory | null = null
  permissions: Permission[] | null = null
  mustReloadList = false
  mountedOpenedTab: ContactProfileTabKey | null = null

  created() {
    GuestPlanningMeetingFetcher.initialize({ eventId: this.storeEvent._id })
  }

  get storeEvent(): Event {
    return assertEvent(this.$store.state.event.event)
  }

  get eventRights(): EventRights {
    const eventRights = this.$store.state.event.rights
    assertEventRights(eventRights)
    return eventRights
  }

  get guestData(): Guest | null {
    return this.loadedGuest
  }

  async mounted() {
    if (this.autoOpen) {
      const { contactProfileGuestId, contactProfileActiveTab } = this.tryGetContactProfileQueryParams()
      if (contactProfileGuestId) {
        await this.loadGuestFromIdAndOpenModal(contactProfileGuestId, contactProfileActiveTab)
      }
    }
  }

  tryGetContactProfileQueryParams(): Partial<ContactProfileQueryParams> {
    const queryParams = convertQueryStringToParamsObject(window.location.search)
    return this.filterContactProfileQueryParams(queryParams)
  }

  filterContactProfileQueryParams(queryParams: Record<string, string>): Partial<ContactProfileQueryParams> {
    const contactProfileQueryParams: Partial<ContactProfileQueryParams> = {}
    contactProfileQueryParamKeys.forEach((key) => {
      const value = queryParams[key]
      if (key === 'contactProfileActiveTab') {
        contactProfileQueryParams[key] = isContactProfileTabKey(value) ? value : undefined
        return
      }
      contactProfileQueryParams[key] = value
    })
    return contactProfileQueryParams
  }

  show(guestId: string, activeTab?: ContactProfileTabKey) {
    if (activeTab) {
      this.mountedOpenedTab = activeTab
    }
    this.setQueryParams({ contactProfileGuestId: guestId, contactProfileActiveTab: activeTab })
    this.isOpen = true
  }

  setQueryParams(queryParams: ContactProfileQueryParams) {
    const newQuery = { ...this.$route.query, ...queryParams }
    const { href } = this.$router.resolve({ query: newQuery })
    history.pushState({}, '', href)
  }

  hide() {
    this.isOpen = false
    this.removeQueryParams()
    if (this.mustReloadList) this.$emit('reload')

    //wait for the animation to finish before resetting the data
    setTimeout(() => {
      this.loadedGuest = null
      this.history = null
      this.mountedOpenedTab = null
    }, 300)
  }

  removeQueryParams() {
    const queryParams = convertQueryStringToParamsObject(window.location.search)
    contactProfileQueryParamKeys.forEach((key) => {
      delete queryParams[key]
    })
    const { href } = this.$router.resolve({ query: queryParams })
    history.pushState({}, '', href)
  }

  @Watch('$route')
  onRouteChange() {
    this.hide()
  }

  hideAndReload() {
    this.mustReloadList = true
    this.hide()
  }

  reloadGuestAndOpenTab(tab?: ContactProfileTabKey) {
    if (!this.loadedGuest) throw new Error('No guest to reload')
    if (tab) this.mountedOpenedTab = tab
    this.mustReloadList = true
    this.loadData({ id: this.loadedGuest._id })
  }

  loadGuestAndOpenModal(guest: Guest, tab?: ContactProfileTabKey) {
    this.loadData({ guest })
    this.show(guest._id, tab)
  }

  loadGuestFromIdAndOpenModal(id: GuestId, tab?: ContactProfileTabKey) {
    this.loadData({ id })
    this.show(id, tab)
  }

  loadGuest({ id, guest }: { id?: string; guest?: Guest }): Promise<Guest> {
    if (guest !== undefined) {
      return Promise.resolve(guest)
    }

    const guestIdToLoad = id

    if (!guestIdToLoad) throw new Error('Could not determine the ID of the guest to load.')

    return getGuestById(this.storeEvent._id, guestIdToLoad)
  }

  async loadData({ id, guest }: { id?: string; guest?: Guest }): Promise<void> {
    this.loading = true
    try {
      this.loadedGuest = await this.loadGuest({ id, guest })
      this.history = await getGuestHistory(this.storeEvent._id, this.loadedGuest._id)
      this.permissions = await listMyPermissions(this.storeEvent._id)
    } finally {
      this.loading = false
    }
  }
}
