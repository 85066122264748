import type { EventRights } from '@/models/Event/EventRights'

export const singleRegistrationMenuSection = {
  key: 'singleRegistration',
  titleSlug: 'EVENT_MENU_SINGLE_REGISTRATION_TITLE',
  iconName: 'user-add-line',
  items: [
    {
      titleSlug: 'EVENT_MENU_SINGLE_REGISTRATION_FORMS',
      routeName: 'event.website.singleRegistration.forms',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => {
        return eventRights.features.registration.type === 'single' && eventRights.features.website.allowed
      }
    },
    {
      titleSlug: 'EVENT_MENU_SINGLE_REGISTRATION_SETTINGS_LINK',
      routeName: 'event.website.singleRegistration.settings',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => {
        return eventRights.features.registration.type === 'single' && eventRights.features.website.allowed
      }
    },
    {
      titleSlug: 'EVENT_MENU_SINGLE_REGISTRATION_RULES_LINK',
      routeName: 'event.website.singleRegistration.rules',
      requiredPermissions: ['configEvent'],
      isAllowedInEvent: (eventRights: EventRights) => {
        return eventRights.features.registration.type === 'single' && eventRights.features.website.allowed
      }
    }
  ]
} as const
