import { StatsScheduledMeetingStatus, StatsUnscheduledMeetingStatus } from '@/digiteventApi'
import type { StatsMeetingStatus, ScheduledMeeting, MeetingGuest, PlanningMeeting } from '@/digiteventApi'
import type { MatchmakingMeetingArea } from '../../../models/Event/modules/Programme'

/**
 * Status strings used only in context of matchmaking session stats.
 * This enum is never used directly in DB, and only reflect arbitrary states dependant on other concrete data.
 */

export function isScheduledMeetingStatus(status: string): status is StatsScheduledMeetingStatus {
  return Object.values(StatsScheduledMeetingStatus).includes(status as StatsScheduledMeetingStatus)
}

function isUnscheduledMeetingStatus(status: string): status is StatsUnscheduledMeetingStatus {
  return Object.values(StatsUnscheduledMeetingStatus).includes(status as StatsUnscheduledMeetingStatus)
}

export function isMeetingStatus(status: string): status is StatsMeetingStatus {
  return isScheduledMeetingStatus(status) || isUnscheduledMeetingStatus(status)
}

export function getMeetingLocationsName({
  meeting,
  areas
}: {
  meeting: ScheduledMeeting
  areas: MatchmakingMeetingArea[]
}): {
  areaName: string
  meetingPointName: string
} | null {
  if (meeting.presenceType === 'virtual') {
    return null
  }
  const area = areas.find((a) => a._id === meeting.location.areaId)
  if (!area) {
    throw new Error('Area not found')
  }
  const meetingPoint = area?.points.find((mp) => mp._id === meeting.location.meetingPointId)
  if (!meetingPoint) {
    throw new Error('Meeting point not found')
  }
  return {
    areaName: area.name,
    meetingPointName: meetingPoint.name
  }
}

export function getMeetingApplicantAndTarget(meeting: PlanningMeeting): {
  applicant: MeetingGuest
  target: MeetingGuest
} {
  const applicant = meeting.guests.find((g) => g.guestId === meeting.applicantId)
  const target = meeting.guests.find((g) => g.guestId !== meeting.applicantId)
  if (!applicant || !target) {
    throw new Error('Applicant or target not found in meeting')
  }
  return { applicant, target }
}
