import type { Account } from '@/models/Account'
import type { ActivationCoupon } from '@/models/Account/Coupon'

import axios from 'axios'

export function getAccount(): Promise<Account> {
  // Retrieve account here
  return axios.get<Account>('backoffice/account').then((res) => res.data)
}

export async function saveAccount(account: Account): Promise<Account> {
  return await axios.put<Account>('backoffice/account', account).then((res) => res.data)
}

interface PasswordChanges {
  password: string
  newPassword: string
}

export async function saveAccountPasswordChange(changes: PasswordChanges): Promise<{ success: true }> {
  return await axios.put<{ success: true }>('backoffice/account/password', changes).then((res) => res.data)
}

export function generateApiKey(): Promise<string> {
  return axios.put<{ apiKey: string }>('backoffice/account/api-key').then((res) => res.data.apiKey)
}

export function revealApiKey(): Promise<string> {
  return axios.get<{ apiKey: string }>('backoffice/account/api-key').then((res) => res.data.apiKey)
}

export function sendEmailVerificationLink(): Promise<void> {
  return axios.post('backoffice/account/send-email-verification-link')
}

export function verifyEmail(token: string, userId: string): Promise<void> {
  return axios.post('/account/verify-email', { token, userId })
}

export function updateAccountEmail(newEmail: string): Promise<void> {
  return axios.put('/backoffice/account/email', { newEmail })
}

export async function getAccountCoupons(): Promise<{ coupons: ActivationCoupon[]; nbOfResults: number }> {
  const res = await axios.get('/backoffice/account/activationCoupons', { params: { page: 1, itemsPerPage: 500 } })

  return res.data
}

export default { getAccount, saveAccount, generateApiKey, revealApiKey, getAccountCoupons }
