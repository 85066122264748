







import { Vue, Component, Prop } from 'vue-property-decorator'
import BackButton from '@/components/features/BackButton.vue'

@Component({
  components: { BackButton }
})
export default class MatchmakingPageHeader extends Vue {
  @Prop({}) readonly pageName?: string
}
