import Guest from '@/models/Guest'
import type { EventId } from '@/models/Event'
import Vue from 'vue'
import { i18n } from '@/utils/i18n'
import { plainToClass } from 'class-transformer'
import type { GuestField, GuestFieldKey } from '@/models/Event/GuestField'
import type { FieldItem } from '@/features/audience/contactEdit/types'
import { createGuest, updateGuest } from '@/services/guest'
import axios from 'axios'
import type { EventLangCode } from '@/models/Event/Localized'

export async function createGuestAndNotify(guest: Partial<Guest>, eventId: EventId) {
  await createGuest(guest, eventId)
  Vue.notify({
    text: i18n.t('GUEST_CREATE_SUCCESS'),
    type: 'success'
  })
}

export async function updateGuestAndNotify(guest: Partial<Guest>, eventId: EventId) {
  await updateGuest(guest, eventId)
  Vue.notify({
    text: i18n.t('GUEST_EDIT_SUCCESS'),
    type: 'success'
  })
}

/**
 * @deprecated use the specific functions instead
 */
export async function saveGuest(guest: Partial<Guest>, eventId: EventId): Promise<Guest> {
  if (!eventId) throw new Error('No event ID was provided')
  // Infer if the guest is new by checking if its _id prop is defined
  const isCreation = !guest._id

  const data = isCreation ? await createGuest(guest, eventId) : await updateGuest(guest, eventId)

  Vue.notify({
    text: i18n.t(isCreation ? 'GUEST_CREATE_SUCCESS' : 'GUEST_EDIT_SUCCESS'),
    type: 'success'
  })

  return plainToClass(Guest, data)
}

export function guestFieldToFieldItem(guestFields: GuestField[]): Map<GuestFieldKey, FieldItem> {
  const map = new Map<GuestFieldKey, FieldItem>()
  guestFields.forEach((guestField) => {
    const fieldItem: FieldItem = {
      key: guestField.key,
      name: i18n.t(guestField.name),
      isRegexField: guestField.type === 'text' && (guestField.pattern?.length ?? 0) > 0,
      field: guestField
    }
    map.set(guestField.key, fieldItem)
  })
  return map
}

export async function updateGuestInvitationStatus(
  eventId: string,
  guestId: string,
  { invitationStatus }: { invitationStatus: string }
): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/guests/${guestId}/invitationStatus`, { invitationStatus })
}

export async function updateGuestSubscriptionStatus(
  eventId: string,
  guestId: string,
  { unsubscribe }: { unsubscribe: boolean }
): Promise<void> {
  await axios.post(`/backoffice/events/${eventId}/guests/${guestId}/campaignSubscription`, { unsubscribe })
}

export async function updateGuestRegisterLanguage(
  eventId: string,
  guestId: string,
  { registerLanguage }: { registerLanguage: EventLangCode | null }
): Promise<void> {
  await axios.put(`/backoffice/events/${eventId}/guests/${guestId}/registerLanguage`, { registerLanguage })
}
