

























































import { Vue, Component, Prop } from 'vue-property-decorator'
import { DigiLabelWithDescriptionLayout } from '../formLayouts'
import type { DigiFormGroupItem, FormItemValidationRules } from './types'
import DigiComingSoonBadge from '../../DigiComingSoonBadge.vue'

export interface ValidationState {
  dirty: boolean
  validated: boolean
  valid: boolean | null
}

@Component({
  components: { DigiComingSoonBadge, DigiLabelWithDescriptionLayout }
})
export default class FormGroupItem extends Vue {
  @Prop({ required: true }) readonly item!: DigiFormGroupItem

  getValidationState({ dirty, validated, valid = null }: ValidationState) {
    return dirty || validated ? valid : null
  }

  get rules() {
    let rules: FormItemValidationRules = {}
    if (this.item.validationRules) {
      rules = { ...this.item.validationRules }
    }
    if (this.item.customValidationRule) {
      rules[`rule_${this.item.key}`] = true
    }
    return rules
  }

  get cssClasses() {
    return {
      [`form-group-item--${this.item.displayMode ?? 'cols'}`]: true,
      'form-group-item--coming-soon': this.item.isComingSoon === true
    }
  }
}
