



















import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

import type { Sort } from '@/models/commons/sort'

@Component({ components: { Draggable } })
export default class SortableFlat extends Vue {
  @VModel() sort!: Sort

  @Prop({ default: false }) readonly disabled!: boolean
  @Prop() readonly hiddenItems?: string[]
  @Prop({ default: true }) readonly show!: boolean
  @Prop({ default: false }) readonly isCustomDesign!: boolean

  isItemHidden(id: string): boolean {
    if (!this.hiddenItems) {
      return false
    }
    return this.hiddenItems.includes(id)
  }
}
