import { GuestFieldType } from '..'
import CustomFieldBase from '../CustomFieldBase'

export default class PhoneCustomField extends CustomFieldBase {
  type = GuestFieldType.Phone as const

  possibleCountries?: string[]
  preferedCountry: string = 'FR'
}

export function isPhoneField(field: CustomFieldBase): field is PhoneCustomField {
  return field.type === GuestFieldType.Phone
}
